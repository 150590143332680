import React from "react";
import { Dialog, DialogTitle, Box, DialogContent,styled,Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import Pagination from "@material-ui/lab/Pagination";
import { dummyImage } from "./assets";
const SearchIcon1 = styled(SearchOutlinedIcon)({
    padding: "10px 0 10px 13px",
    background: 'rgba(227,234,244,0.5)',
    "@media(max-width: 500px)": {
        padding: "10px 0 10px 8px",
        fontSize: '18px'
    },
});

const SearchInput1 = styled('input')({
    fontSize: "15px",
    outline: "none",
    border: "none",
    width: "85%",
    padding: "10px 3px",
    backgroundColor: "#F8FAFE",
    "@media(max-width: 500px)": {
        fontSize: '12px',
        overflow: 'hidden',
        width: "70%",
        padding: "10px 0px",
    },
});
const useStyles = makeStyles({
    dialogParent: { width: 795, maxWidth: 795, margin: "0px auto"
  ,
  '& .MuiDialog-paperFullWidth': {
        height: '702px',
        width: '720px',
        boxShadow: 'none',
    },

    '& .MuiBackdrop-root': {
        backgroundColor: 'rgba(0, 0, 0, 0.15)',
    }

},
    dialogHeader: { display: "flex", justifyContent: "space-between" },
    dialogContaentContainer: { width: "100%", display: "grid", gridTemplateColumns: " repeat(2, 1fr)", gap: "16px", margin: '10px 0px' },
    inviteMemberContainer: { width: "324px", boxSizing: "border-box", height: "83px", border: "1px solid #E3EAF4", borderRadius: "12px", display: "flex", justifyContent: "space-between", padding: "12px", },
    inviteMemberLeftDiv: { display: "flex", gap: "8px" },
    memberInfo: { display: "flex", flexDirection: "column" },
    memberName: {
        color: "#17181D",

        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 600,
    },
    memberPosition: {
        color: "#17181D",

        fontFamily: "Urbanist",
        fontSize: "14px",
        fontWeight: 500
    },
    memberAddress: {
        color: "#6D89AF",

        fontFamily: "Urbanist",
        fontSize: "12px",
        fontWeight: 500,
    },
    memberExperienContainer: { display: "flex", flexDirection: "column", justifyContent: "flex-end" },
    experienceDiv: { display: "flex", gap: "3px" },
    bagIcon: { color: "black", width: "16px", height: "16px" },
    yearsDiv: {
        color: "#6D89AF",

        fontFamily: "Urbanist",
        fontSize: "12px",
        fontWeight: 600,
    },
    headerLabel: {
        color: ' #17181D',
        textAlign: 'center',
        fontFamily: 'Urbanist',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: 'normal',
        letterspacing: '0.2px',

    },
    SearchDiv: {
        display: "flex",
        width: '487px',
        height: '48px',
        borderRadius: '12px',
        //background: 'rgba(227,234,244,0.5)',
        
        alignItems: "center",
        paddingLeft:'15%',
        overflowX: 'hidden',
        marginBottom: '16px',
        "@media screen and (max-width: 500px)": {
            minWidth: "100px",
            width: '216px',
            marginRight: "0px",
            height:'40px'
        }
    },
    searchicon: {
        //marginRight: '6px',
        fill: '#6D89AF',
        fontSize: "14px",
        paddingTop: '13px',
    }
});

export default function ProductionHouseInviteMemberDialog(props: any) {
    const classes = useStyles();

    return (
        <>
            <Dialog
                // @ts-ignore
                maxWidth={"720px"}
                maxHeight={"702px"}
                className={classes.dialogParent}
                fullWidth={true}
                open={props.inviteMemberModal}
                onClose={props.handleInviteMemberModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Box className={classes.dialogHeader}>
                        <span></span>
                        <span className={classes.headerLabel} >
                            {props?.isInviteVendor ? "Invite for Job" :"Invite Member"}
                        </span>
                        <span onClick={props.handleInviteMemberModal}><CloseIcon /></span>
                    </Box>
                </DialogTitle>
                <DialogContent>
                <Grid className={classes.SearchDiv}>
                                   <SearchIcon1 className={classes.searchicon} />
                                   <SearchInput1
                                     placeholder="Search..."
                                     style={{
                                       fontSize: '14px',
                                       fontWeight: 500,
                                       fontFamily: 'Urbanist',
                                       letterSpacing: '0.25px',
                                       background: 'rgba(227,234,244,0.5)'
                                     }}
                                      
                                      onChange={(event:any)=>props.handleSearchstate(event)}
                                   />
                                 </Grid>
                    <Box className={classes.dialogContaentContainer} style={{ minHeight: "100px" }}>
                        {
                            props.memberList.map((ele: any) => {
                                return <div
                                className={classes.inviteMemberContainer}
                                onClick={props?.isInviteVendor ? ()=> props.navigateToVendorProfiles(ele.attributes.profile_id) :() => props?.personaldetailmodelopen((ele.attributes.profile_id))}
                                key={ele.id}
                            >
                            <div className={classes.inviteMemberLeftDiv}>
                                <div className="image">

                                    <img src={ele.attributes?.profile_image||dummyImage} width={59} height={59} style={{ borderRadius: "14px" }} alt="face" />

                                    

                                </div>
                                <div className={classes.memberInfo}>
                                    <span className={classes.memberName}>{ }{ele.attributes.full_name}</span>
                                    <span className={classes.memberPosition}>{ele.attributes.position}</span>
                                    <span className={classes.memberAddress}>{ele.attributes.city ? ele.attributes.city : ""} {ele.attributes.state ? ` (${ele.attributes.state})` : ''}</span>
                                </div>
                            </div>
                            <div className={classes.memberExperienContainer}>
                                <div className={classes.experienceDiv}>
                                    <WorkOutlineOutlinedIcon className={classes.bagIcon} />
                                    <span className={classes.yearsDiv} >{ele?.attributes?.experience}</span>
                                </div>
                            </div>
                        </div>
                            })
                        }
                    </Box>
                    {props?.count ?
                            (<Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Pagination count={Math.ceil((props?.count) / 9)} page={props?.page} onChange={props?.handlePageChange} />
                                </Grid>
                            </Grid>)
                            : null}
                </DialogContent>
                
               
            </Dialog>
        </>
    );
}
