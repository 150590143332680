import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData,setStorageData} from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");


export interface IBalanceSheetProject {
  id: string;
  attributes: {
    project_name: string;
    current_balance: number | null;
    overall_budget: number | null;
    production_house: string;
    image: string | null;
    created_at: string;
    manage_balance_sheet: boolean;
    view_balance_sheet: boolean;
  }
}

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  loading: boolean;
  isBalanceSheetSideBarOpen: boolean;
  isBalanceSheetHeaderDropdownOpenVal:boolean;
  txtSavedValue: string;
  isLoading:boolean;
  balanceSheetData:IBalanceSheetProject[];
  totalPages:number
  searchBalanceSheet: string
  currentpage: number,


  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string | number;
  // Customizable Area End
}

export default class BalanceSheetController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getBalanceSheetDetailsCallId:string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      isBalanceSheetSideBarOpen:true,
      isBalanceSheetHeaderDropdownOpenVal:false,
      txtSavedValue: "A",
      isLoading: false,
      balanceSheetData:[],
      totalPages:0,
      currentpage: 1,
      searchBalanceSheet: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let valueBalanceSheet = message.getData(getName(MessageEnum.AuthTokenDataMessage));

    
      this.setState({ txtSavedValue: valueBalanceSheet });
      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + valueBalanceSheet
      );

    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      if(apiRequestCallId === this.getBalanceSheetDetailsCallId) {
        this.getBalanceSheetApiResponse(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
  await this.getAllBalanceSheetProjects()
  }

  handleBalanceSheetSideBar = () => {
    this.setState({
        isBalanceSheetSideBarOpen: !this.state.isBalanceSheetSideBarOpen
    });
  };

handleBalanceSheetHeaderDropdownVal = () => {
    this.setState({
        isBalanceSheetHeaderDropdownOpenVal: !this.state.isBalanceSheetHeaderDropdownOpenVal
    })
  };
getAllBalanceSheetProjects = async () => {
  this.getBalanceSheetDetailsCallId = await this.getBalanceSheet(configJSON.getBalanceSheetApiEndPoint + `?per_page=${configJSON.balanceSheetProjectListPerPage}&page=${1}`);
};

handleBalanceSheetSearchData = async (event: any) => {
  this.setState({
    searchBalanceSheet: event.target.value,
  });
  this.getBalanceSheetDetailsCallId = await this.getBalanceSheet(configJSON.getBalanceSheetApiEndPoint + `?per_page=${configJSON.balanceSheetProjectListPerPage}&page=${this.state.currentpage}&search=${event.target.value}`);
}

handleManageSheetNavigation = (id: string) => {
  setStorageData("BalanceSheetId", id);
  this.props.navigation.navigate("ManageBalanceSheet");
}

handleViewSheetNavigation = (id: string) => {
  setStorageData("BalanceSheetId", id);
  this.props.navigation.navigate("ViewBalanceSheet");
}

handleBalanceSheetPagination = (event: any, value: number) => {
  this.setState({
    currentpage: value,
  });
  this.getBalanceSheet(configJSON.getBalanceSheetProjectListAPI + `?per_page=${configJSON.balanceSheetProjectListPerPage}&page=${value}`);
}

  getBalanceSheetApiResponse = (responseJson: any) => {
    if (responseJson.data) {
        this.setState({ 
          balanceSheetData: responseJson.data,
          totalPages: responseJson.meta?.total_count
      });
    } else if (responseJson.errors[0]?.token) {
      this.showAlert("Success", responseJson.errors[0].token)
    }
    this.setState({ isLoading: false });
  }
   formatBalance = (amount: number): string => {
    if (amount >= 10000000) {
      return `${(amount / 10000000).toFixed(1)}Cr`;
    } else if (amount >= 100000) {
      return `${(amount / 100000).toFixed(1)}L`;
    } else if (amount >= 1000) {
      return `${(amount / 1000).toFixed(1)}K`;
    } else {
      return amount.toFixed(2);
    }
  };

  getBalanceSheet = async (endPoint: string) => {
    const authenticationToken = await getStorageData('token', false)
    const header = {
        "Content-Type": configJSON.exampleApiContentType,
        "token": authenticationToken
    };
      this.setState({isLoading:true})
    const requestMessageGetBalanceSheet = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getBalanceSheetDetailsCallId = requestMessageGetBalanceSheet.messageId;
    requestMessageGetBalanceSheet.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
  
    requestMessageGetBalanceSheet.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessageGetBalanceSheet.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAPIMethod
    );
    runEngine.sendMessage(requestMessageGetBalanceSheet.id, requestMessageGetBalanceSheet);
    return requestMessageGetBalanceSheet.messageId;
  };
  

  // Customizable Area End
}
