import { BlockComponent } from "../../../framework/src/BlockComponent";


// Customizable Area Start
import React, { ChangeEvent } from "react";
type ActiveEmployeeId = number | string | undefined;
import { City,IState,State } from "country-state-city";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import moment from "moment";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    type: string;
    overviewData: any;
    // Customizable Area End
}

  export interface ICity {
    name: string
    countryCode: string
    stateCode: string
    latitude: string | null | undefined
    longitude: string
    }
  
  export interface Timezone {
  
  gmtOffsetName: string
  abbreviation: string
  tzName: string
  zoneName: string
  gmtOffset: number
  }

 export  interface Employee {

    department: string;
    level: string;
    designation: string;
    phoneNumber: string;
    role: string;
    permissions?:any
    id: number;
    image: string;
    name: string;
}
interface Department {
 
  error: boolean;
  value: string;
  name: string;
  errMessage: string;
}
export interface SelectDepartment{
  value: { value: string; label: string };
  error: boolean;
  errMessage: string;
}
export interface Permission {
  id: string;
  type: string;
  attributes: {
      id: number;
      name: string;
      slug: string;
      status: boolean;
  };
}
export interface IndianState {
  
  countryCode: string;
  name: string;
  isoCode: string;
  latitude: string;
  longitude: string;
}
export interface ProjectDepartment {
  id: string;
  type: string;
  attributes: {

      level: any; 
      name: string;
      pipeline_department: boolean;
      project_id: number;
  };
}
export interface ProjectDefaultImage {
  id: string;
  type: string;
  attributes: {

      description: string;
      name: string;
      image: string;
  };
}

interface S {
    // Customizable Area Start
    projectId:string|number;
    isSideBarOpen:boolean;
    isDropdownOpen:boolean;
    activeStepIndex:any;
    stepOneInputs:any;
    vendorProfileData: any;
    vendorProfileDataError:any
    openUploadProjectImageModal:boolean;
    addNewDepartmentModal:boolean;
    addNewMemberModal:boolean;
    addNewMemberModaltab:number;
    projectImage:any;
    imagePreviewUrl:string; 
    opendefaultImageModal:boolean;
    inviteMemberModal:boolean;
    invoiceFile:any;
    invoicePreviewUrl:string;
    validationStatus:any;
    selectedImageIndex:number;
    addNewMemberDetails:any;
    selectNewDepartmentModal : boolean,
    openSMSDialog : boolean,
    permissionModal:boolean,
    activeEmployeeId:ActiveEmployeeId,
    openPublishProjectDialog:boolean,
    memberData:any[],
    allProjectDetail:any[];
    newDepartmentName:Department,
    selectDepartMent: SelectDepartment,
    permissionAccess: Permission[],
    stateList: IState[];
    cityList: any[];
    departmentList:ProjectDepartment[];
    searchQuery:string;
    defaultImagesList:ProjectDefaultImage[];
    inviteMemberList:any[];
    inviteMemberListSearch:any[];
    activeEmployeeList:any[];
    rolesList:any[];
    addNewMemberdepartmentList:any[];
    countryCodeSelected:string;
    isLoading:boolean;
    personaldetailmodel:boolean;
    searchinvitevendor: string;

EditProject:any;

    notification: { type: string, open: boolean, message: string, route?: string };

    errorMessage:string;
    vendorInviteCount : number;
    currentpage: number;
    isModelOpen:boolean;
    modelType: string;
    manageModelPermssionList: any[];
    departmentsList: any[];
    manageModelSelectedDept: any;
    permssionsList: any[];
    employeeIdToUpdate: string;
    projectMembersJobRole: any[];
    totalCountPMJR: number;
    projectMembersJobRoleBreadCrumb: any;
    dummydepartments:any;
    checkedDepartments:any;
    cityData:any;
    stateData: any;
    emailLoginNotification: { type: string, open: boolean, message: string, route?: string };
    imageId:any;
    seletedEmp:{
      "id": string,
      "type": string,
      "attributes": {
        "project_id": number | null,
        "project_department_id": number | null,
        "account_id": number | null,
        "permissions":
        {
          "id": number | null,
          "name": string,
          "slug": string
        }[],
        "full_name": string,
        "activated": boolean,
        "full_phone_number": string,
        "country_code": number | null,
        "phone_number": number | null,
        "photo": string,
        "project_department": {
          "name": string,
          "level": null
        },
        "role": {
          "name": string,
          "id": number | null
        }
      }
    }
    samePhoneError: string
    open:boolean
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class AddNewProjectController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    //layoutRef: any = null;
    projectMembersJobRoleTitle: string = "Add New Project";
    videoRef: React.RefObject<HTMLVideoElement>;
    canvasRef: React.RefObject<HTMLVideoElement>;
    streamRef: MediaStream | null;
    createProjectId:string="";
    UpdateProjectId:string="";
    allemployeeApiCalledId:string=""
    allDepartmentsID:string="";
    createDepartmentId:string="";
    updateDepartmentsId:string="";
    getPrjectDetailApiCalledId:string=""
    defaultImagesID:string="";
    allEmployeeListID:string="";
    addPipelineEmployeeApiCalledId: string = "";
    vendorListID:string="";
    getsearchInviteVendor:string="";
    rolesListID:string="";
    getPipelineEmployeeDepartmentListApiCalledId: string = "";
    departmentListID:string="";
    permissionsListID:string="";
    savePermissionID:string="";
    publishProjectID:string="";
    searchDebounceTimer:any=null
    deleteemployeedataid:string="";
    deleteemployeedataidactive:string="";
    managePipelineEmployeeAccessibilityApiCalledId: string = "";
    getPipelineEmployeePermissionListApiCalledId:string=""
    getPipelineEmployeeRoleListApiCalledId:string=""
    updatedepartmentcallidAddProject:any
    getCitiesMessageId: string = "";
    getStatesMessageId:string=''
    postSmsInvite:string=""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
     
        this.subScribedMessages = [
          getName(MessageEnum.RestAPIResponceMessage),
          getName(MessageEnum.NavigationPayLoadMessage),
          getName(MessageEnum.ReciveUserCredentials),
          getName(MessageEnum.CountryCodeMessage)
      ];
        this.receive = this.receive.bind(this);

        this.state = {
            // Customizable Area Start
            projectId:"",
         
           activeStepIndex:0,
           vendorProfileDataError: {
           
            "profile[state]": { require: false },
            "profile[city]":{require: false},
           
    
           
           
          },
           vendorProfileData: {
           
             "profile[state]":'',
             "profile[city]":'',
          
            
          },
           stepOneInputs:{
            projectName:"",
            projectStartDate:"",
            projectEndDate:"",
            selectedState:{name:"Select State",value:"Select State",countryCode:"",isoCode:""},
            selectedCity:{name:"Select City",value:"city"},
            projectDescription:"",
            projectAddress:""
           },
           isSideBarOpen:true,
    searchinvitevendor:'',

           isDropdownOpen:false,
           openUploadProjectImageModal:false,
           addNewDepartmentModal:false,
           addNewMemberModal:false,
           addNewMemberModaltab:1,
           projectImage:"",
           imagePreviewUrl:"",
           opendefaultImageModal:false,
           defaultImagesList:[],
           inviteMemberModal:false,
           inviteMemberList:[],
           inviteMemberListSearch:[],
           projectMembersJobRole: [],
           invoiceFile:{},
           totalCountPMJR: 0,
           projectMembersJobRoleBreadCrumb: [
            { title: "Home", link: "Projects" },
            { title: "Projects", link: "Projects" },
            { title: "", link: "Projects" },
            { title: "Add New Projects" },
          ],
           invoicePreviewUrl:"",
           validationStatus:{
            projectName:{label:"Project name",valid:false,message:""},
            projectStartDate:{label:"Project start date",valid:false,message:""},
            projectEndDate:{label:"Project end date",valid:false,message:""},
            selectedState:{label:"Project state",valid:false,message:""},
            selectedCity:{label:"Project city",valid:false,message:""},
            projectDescription:{label:"Project description",valid:false,message:""},
            projectAddress:{label:"Project address",valid:false,message:""}
          },
          selectedImageIndex:-1,
          addNewMemberDetails:{
            fullName: { value: '', error: false, errorMessage: '' },
            phoneNumber: { value: '', error: false, errorMessage: '' },
            selectedDept: { value: { label: 'Select Dept', value: 'Select Dept' }, error: false, errorMessage: '' },
            selectedRole: { value: { label: 'Select Role', value: 'Select Role' }, error: false, errorMessage: '' },
          },
          selectNewDepartmentModal : false,
          openSMSDialog : false,
          permissionModal:false,
          activeEmployeeId:-1,
          openPublishProjectDialog:false,
          memberData:[...configJSON.memberData],
          newDepartmentName:{
            value:"",
            name:"departmentName",
            error:false,
            errMessage:""
          },
          selectDepartMent:{
            value:{value:"Select Dept",label:"Select Dept"},
            error:false,
            errMessage:""
          },
          permissionAccess:[],
          stateList:[],
          cityList:[],
          departmentList:[],
          searchQuery:"",
          activeEmployeeList:[],
          rolesList:[],
          addNewMemberdepartmentList:[],
          countryCodeSelected:'',
          isLoading:false,
          errorMessage:"",
          EditProject:null,
          allProjectDetail:[],

          vendorInviteCount : 0,
          currentpage: 1,
          isModelOpen: false,
          modelType: "",
          manageModelPermssionList: [],
          departmentsList: [],
          manageModelSelectedDept: null,
          permssionsList: [],
          employeeIdToUpdate: "",
          notification: { type: "", open: false, message: "" },
          checkedDepartments:[],
          dummydepartments:[],
          emailLoginNotification: { type: "", open: false, message: "" },
          personaldetailmodel:false,
          cityData:[],
          stateData: [],
          imageId:null,
          seletedEmp:{
            "id": "",
            "type": "",
            "attributes": {
              "project_id": null,
              "project_department_id": null,
              "account_id": null,
              "permissions":[],
              "full_name": "",
              "activated": false,
              "full_phone_number": "",
              "country_code": null,
              "phone_number": null,
              "photo": "",
              "project_department": {
                "name": "",
                "level": null
              },
              "role": {
                "name": "",
                "id": null
              }
            }
          },
          samePhoneError: "",
          open:false
          // Customizable Area End
            // Customizable Area End
        };
        // Customizable Area Start
    
      this.streamRef = null;
      this.videoRef = React.createRef();
      this.canvasRef = React.createRef();

    runEngine.attachBuildingBlock(this, this.subScribedMessages);
        // Customizable Area End
    }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
  
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.createProjectId) {
                 
       this.createProjectpart(apiRequestCallId, responseJson)
      }
      else if(apiRequestCallId===this.updatedepartmentcallidAddProject){
      
        this.getDepartmentList()
      }
      if(apiRequestCallId===this.postSmsInvite){      
        this.handleSMSInviteResponse(responseJson)
      }

      if(apiRequestCallId===this.getPrjectDetailApiCalledId)
      {
      
       this.Projectdetailresponse(apiRequestCallId,responseJson)
      }
      if(apiRequestCallId===this.UpdateProjectId)
      { if(responseJson.errors)
        {
          this.setState({ emailLoginNotification: { open: true, type: "error", message: "end date must be greater than start date"} });
       }
       else
       {
        await setStorageData("projectidPH",responseJson.data.id)
        this.props.navigation.navigate("ProjectDetailPH")
       }
      }
      this.ResponseAddProject(apiRequestCallId,responseJson)


    }
    this.getTokenAndCountryCodeSelected(message);
    // Customizable Area End
  }
  Projectdetailresponse(apiRequestCallId:any,responseJson:any) {
    this.setState({allProjectDetail:responseJson.data})
    this.setState({ stateList: State.getStatesOfCountry("IN") }, () => {
    })
    const stateDetailedData = this.state.stateList.find((data: any) => data.name ===responseJson.data.attributes.state);
    this.setState({
      cityList: City.getCitiesOfState(
        this.state.stepOneInputs.selectedState?.countryCode,
        this.state.stepOneInputs.selectedState.isoCode
      )})
    

      

    this.setState({ stepOneInputs: { ...this.state.stepOneInputs, projectName:responseJson.data.attributes.project_name,projectDescription:responseJson.data.attributes.description,projectAddress:responseJson.data.attributes.address,selectedState:stateDetailedData
    } })
    this.setState({
      cityList: City.getCitiesOfState(
        this.state.stepOneInputs.selectedState?.countryCode,
        this.state.stepOneInputs.selectedState.isoCode
      )})
      const cityDetailedData = this.state.cityList.find((data: any) => data.name ===responseJson.data.attributes.city);
      this.setState({ stepOneInputs: { ...this.state.stepOneInputs, selectedCity: cityDetailedData,projectStartDate:moment(responseJson.data.attributes.start_date).format('YYYY-MM-DD'),projectEndDate:moment(responseJson.data.attributes.end_date).format('YYYY-MM-DD')
      } })
     



  this.setState({imageId:responseJson.data.attributes.image_id})
    
    console.log("imageid.............",this.state.imageId)
  
    this.setState({ projectImage:responseJson.data.attributes.image ,imagePreviewUrl:responseJson.data.attributes.image });
   
  }

  createProjectpart=async(apiRequestCallId:any,responseJson:any)=>
  {
    if(responseJson.errors)
    {
      this.setState({ emailLoginNotification: { open: true, type: "error", message: responseJson.errors[0]} });
   }
  else{
    await setStorageData("jobRoleproject_Id",responseJson.data.id);
              await setStorageData("projectidPH",responseJson.data.id)
              await setStorageData("projectname",responseJson.data.attributes.project_name)
    this.setState({ activeStepIndex: 1 });
  
      this.allemployeeList()

  this.setState({ projectId: responseJson.data.id }, () => {
    this.getAllDepartmentList(this.state.searchQuery)
  })
  }
  }
  ResponseAddProject(apiRequestCallId:any,responseJson:any){
   
     if (apiRequestCallId === this.allDepartmentsID) {
      this.setState({ departmentList: responseJson.data,isLoading:false })
    } else if (apiRequestCallId === this.createDepartmentId) {
      this.getAllDepartmentList(this.state.searchQuery)
    } else if (apiRequestCallId === this.defaultImagesID) {
      this.setState({ defaultImagesList: responseJson.data })
    }
    else if (apiRequestCallId === this.allEmployeeListID) {
     
      this.setState({ activeEmployeeList: responseJson.data,memberData:responseJson.data })
    } else if (apiRequestCallId === this.vendorListID) {
      this.getinviteVenorList(responseJson)
      
    }
    else if (apiRequestCallId === this.getsearchInviteVendor){
      this.getinviteVenorList(responseJson)

    }
    else if (this.managePipelineEmployeeAccessibilityApiCalledId === apiRequestCallId) {
      this.managePipelineEmployeeAccessibilityHandleResponse(responseJson);
    }
    else if (this.getPipelineEmployeePermissionListApiCalledId === apiRequestCallId) {
      this.getPipelineEmployeePermissionListHandleResponse(responseJson, "permssionsList");
    }
   this.responseremaingpart(apiRequestCallId, responseJson)

    
  }
  responseremaingpart(apiRequestCallId:any,responseJson:any){
     if(apiRequestCallId === this.deleteemployeedataid)
    {

      this.getAllMembersList();
      this.props.navigation.navigate("addNewProject")
      this.setState({activeStepIndex:2})
    }
    if(apiRequestCallId === this.deleteemployeedataidactive)
    {

      this.getAllMembersList();
      this.props.navigation.navigate("addNewProject")
      this.setState({activeStepIndex:2})
    }
    else if (this.getPipelineEmployeeRoleListApiCalledId === apiRequestCallId) {
      this.getPipelineEmployeePermissionListHandleResponse(responseJson, "rolesList");
    }
    else if (this.getPipelineEmployeeDepartmentListApiCalledId === apiRequestCallId) {
      this.getPipelineEmployeeDepartmentListHandleResponse(responseJson);
    }
    else if (apiRequestCallId === this.rolesListID) {
      const prepareRolesList = responseJson.data.map((ele: any) => ({ value: ele.name, label: ele.name, id: ele.id }))
      this.setState({ rolesList: prepareRolesList })
    }
    else if (apiRequestCallId === this.departmentListID) {
      this.setState({ addNewMemberdepartmentList: responseJson?.data })
    }
    else if (apiRequestCallId === this.permissionsListID) {
      this.setState({ permissionAccess: responseJson?.data })

    } else if(apiRequestCallId === this.allemployeeApiCalledId)
    {
           console.log("Api response..")
    } 
   else if (this.addPipelineEmployeeApiCalledId === apiRequestCallId) {
    const phoneError=responseJson?.errors && responseJson?.errors.message
    if(phoneError){
      this.setState({samePhoneError:phoneError})
    }
    this.addPipelineEmployeeHandleResponse(responseJson);
  }
    else if (apiRequestCallId === this.savePermissionID) {
      this.setState({ invoicePreviewUrl:"", addNewMemberDetails:{
        fullName: { value: '', error: false, errorMessage: '' },
        phoneNumber: { value: '', error: false, errorMessage: '' },
        selectedDept: { value: { label: 'Select Dept', value: 'Select Dept' }, error: false, errorMessage: '' },
        selectedRole: { value: { label: 'Select Role', value: 'Select Role' }, error: false, errorMessage: '' },
  },
  invoiceFile:{},
  permissionAccess:[]
}, ()=>{
    this.getAllMembersList();
    this.handleAddNewMemberModal();

  
  })
    }
  }
  
  tPipelineEmployeePermissionListHandleResponse = (pmjrResponseJson: { data: any }, stateName: keyof S) => {
    if (pmjrResponseJson.data) {
      this.setState(({
        [stateName]: pmjrResponseJson.data, isLoading: false
      } as unknown) as Pick<S, keyof S>);
    }
  }
  handleViewinvoice=async()=>{
    
    this.setState({activeStepIndex:2})
    if(this.state.activeStepIndex==2){
      window.location.replace(`/AddNewProject?3`);
    }else{
      window.location.replace('/AddNewProject');  
    }
   

  }
  managePipelineEmployeeAccessibilityHandleResponse = async (pmjrResponseJson: { data: any, meta: { message: string }, errors: { token: string }[] | { message: string } }) => {
    if (pmjrResponseJson.data) {
      
      this.allEmployeeListID = await this.apiCallAddProject(configJSON.GET_Method, configJSON.employeeAPI +`project_id=${this.state.projectId}`);
    } 
    this.getDepartmentList()
    this.setState({ isLoading: false });
  }
  getPipelineEmployeeDepartmentListHandleResponse = (pmjrResponseJson: { data: any, errors: { token: string }[] | { message: string } }) => {
    if (pmjrResponseJson.data) {
      this.setState({
        departmentsList: pmjrResponseJson.data
      });
    } 
    this.setState({ isLoading: false });
  }
  getPipelineEmployeePermissionListHandleResponse = (pmjrResponseJson: { data: any }, stateName: keyof S) => {
    if (pmjrResponseJson.data) {
      this.setState(({
        [stateName]: pmjrResponseJson.data, isLoading: false
      } as unknown) as Pick<S, keyof S>);
    }
  }
  getinviteVenorList=(response:any)=>{
    this.setState({ inviteMemberList: response.data })
      this.setState({inviteMemberListSearch:response.data})
      this.setState({vendorInviteCount:response.meta.count})
  }
  async componentDidMount() {
    
    let id = await getStorageData("jobRoleproject_Id");
    this.getPipelineEmployeeDepartmentListApiCalledId = await this.apiCallAddProject(configJSON.GET_Method, configJSON.getPipelineEmployeeDepartmentListApiEndPoint + id);
    this.getPipelineEmployeePermissionListApiCalledId = await this.apiCallAddProject(configJSON.GET_Method, configJSON.getPipelineEmployeePermissionListApiEndPoint);
    this.getPipelineEmployeeRoleListApiCalledId = await this.apiCallAddProject(configJSON.GET_Method, configJSON.getPipelineEmployeeRoleListApiEndPoint);
    this.getDepartmentList()
  
    this.allemployeeList() 
   
    const projectData = localStorage.getItem('projectData');
    
    this.setState({ stateList: State.getStatesOfCountry("IN") }, () => {
    })
    let isSideBarOpen = await getStorageData("sidebarvalue");
    this.setState({ isSideBarOpen: JSON.parse(isSideBarOpen) })
    
    this.setState({ memberData: this.state.memberData.map((ele) => ({ ...ele, permissions: [...configJSON.permissions] })) })
    this.getAllDefaultImageList();

    
    
    
  this.getAllDepartmentList(this.state.searchQuery)
  const projecttype=await getStorageData("projecttype");
    this.setState({EditProject:projecttype})
    
   
    if(projecttype=="editproject")
    {
      this.previousdata();
    }

    this.getVendorList();

    
    
    const countryname=await getStorageData("countryname")
    localStorage.removeItem("value")

    
    this.setState({countryCodeSelected:countryname})
  }
  getStatesAndCitiesApiCall = async (endPoint: string) => {
    const XCSCAPIKEY = "Z1dmZFJDWjlMSjl0M082ZUUyU3JESkJCQTdZeTd6UEVINXdreTdjWQ=="
    const header = {
      "Content-Type": "application/json",
      "X-CSCAPI-KEY": XCSCAPIKEY
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  previousdata = async () => {

    let logintoken = await getStorageData("token");
    let editid = await getStorageData("editproject");
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": logintoken
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPrjectDetailApiCalledId = requestMessage.messageId;
   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProjectDetailApiEndPoint + `/${editid}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
  };   
  handlePublishProject=()=>{
    this.publishProject();
    this.handlePublishProjectModal()
  }
  handleChangeNotification = (notiType: string, notiOpen: boolean, notiMessage: string, notiRoute?: string) => {
    this.setState({ notification: { type: notiType, open: notiOpen, message: notiMessage, route: notiRoute || "" } });
  }

  handleCloseNotification = () => {
    this.setState({ notification: { type: "", open: false, message: "" } });
  }
  apiCallAddProject = async (pmjrMethodAddproject: string, pmjrEndPointAddproject: string, pmjrFormDataAddproject?: any) => {
    const pmjrAutoToken = await getStorageData("token");
    const pmjrHeader = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": pmjrAutoToken,
    };
    this.setState({ isLoading: true });
    const pmjrRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    pmjrRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      pmjrEndPointAddproject
    );
    pmjrRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(pmjrHeader)
    );
   
    pmjrFormDataAddproject && pmjrRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      pmjrFormDataAddproject
    );
    pmjrRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(pmjrFormDataAddproject ? { token: pmjrAutoToken } : pmjrHeader)
    );
    pmjrRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      pmjrMethodAddproject
    );
    runEngine.sendMessage(pmjrRequestMessage.id, pmjrRequestMessage);
    return pmjrRequestMessage.messageId;
  };
 
  
  handleSearch = (event:any) => {
   
    const updatedList: any[] | null = this.state.inviteMemberListSearch && (this.state.inviteMemberListSearch.filter((state) => {
      return state.attributes.full_name.toLowerCase().includes(event.target.value.toLowerCase())
    }) ?? null);
    this.setState({
      inviteMemberList: updatedList
    })
  };

  handleEmailLoginCloseNotification = () => {
    this.setState({ emailLoginNotification: { type: "", open: false, message: "" } });
  }
  publishProject=async()=>{
    const authenticationToken = await getStorageData('token', false)
    const header = {
      "token": authenticationToken,
      "Content-Type": "application/json"
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.publishProjectID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.publishProjectAPI}${this.state.projectId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
   let httpBody={ 
      data:{
          attribute:{
              published: true
          }
      }
   }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }



 handleDepartmentLevelChange = (event:any, departmentId:string) => {
        const { value } = event.target;
        this.setState(prevState => ({
            departmentList: prevState.departmentList.map(department => {
                if (department.id === departmentId) {
                    return {
                        ...department,
                        attributes: {
                            ...department.attributes,
                            level: value,
                        },
                    };
                }
                return department;
            }),
        }), () => {
            
            localStorage.setItem('departmentList', JSON.stringify(this.state.departmentList));
        });
    };
  
  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.stepOneInputs.selectedState.name !== this.state.stepOneInputs.selectedState.name) {
      this.setState({
        cityList: City.getCitiesOfState(
          this.state.stepOneInputs.selectedState?.countryCode,
          this.state.stepOneInputs.selectedState.isoCode
        ),
        stepOneInputs: {
          ...this.state.stepOneInputs,
          selectedCity: { name: "Select City", value: "city" },
        }
      })
    }
  }

  togglePipelineDepartmentAddProject = (id:any) :void=> {
    this.setState((prevState:any) => {
      
      const departmentIndexAddProject = prevState.departmentList.findIndex((dept:{id:string}) => dept.id === id);
      const updatedDepartmentListAddProject = [...prevState.departmentList];
     
      if (departmentIndexAddProject !== -1) {
        const updatedDepartment = {
          ...updatedDepartmentListAddProject[departmentIndexAddProject],
          attributes: {
            ...updatedDepartmentListAddProject[departmentIndexAddProject].attributes,
            pipeline_department: !updatedDepartmentListAddProject[departmentIndexAddProject].attributes.pipeline_department
          }
        };
        updatedDepartmentListAddProject[departmentIndexAddProject] = updatedDepartment;

        
        const checkedDepartments = updatedDepartment.attributes.pipeline_department ?
          [...prevState.checkedDepartments, { id: updatedDepartment.id, name: updatedDepartment.attributes.name, level: updatedDepartment.attributes.level }] :
          prevState.checkedDepartments.filter((dept: { id: string; }) => dept.id !== updatedDepartment.id);

        return { departmentList: updatedDepartmentListAddProject, checkedDepartments };
      }

      return prevState;
    });
    
    this.setState({dummydepartments:this.state.checkedDepartments});
    
  };
  handleSearchDebounce = () => {
    this.searchDebounceTimer = setTimeout(() => {
      this.getAllDepartmentList(this.state.searchQuery)
    }, 300);
  }
  handleManageAccessibility = (permissions: any, department: any, employeeId: string) => {
    this.handleModelOpen("manageAccessibility");
    const manageModelPermssionList = this.state.permssionsList.map((permission: any) => {
      return { ...permission, selected: permissions.some((data: any) => data.slug === permission.attributes.slug) }
    });
    const selectedDept = this.state.addNewMemberdepartmentList.find((dept: any) => department && dept.attributes.name === department.name && dept.attributes.level === department.level);
   
    this.setState({ manageModelPermssionList: manageModelPermssionList, manageModelSelectedDept: department ? selectedDept.id : "", employeeIdToUpdate: employeeId });
  }
  
  handleSearchDepartment = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    this.updateDepartments()
    if (this.searchDebounceTimer) {
      clearTimeout(this.searchDebounceTimer);
    }
    this.setState({ searchQuery: event.target.value }, this.handleSearchDebounce)
  }

  savePermissionsAPICall = async (formData: any) => {
   
    const emplyeeToUpddate = this.state.activeEmployeeList.find((data: any) => data.id === this.state.employeeIdToUpdate);
   
    this.managePipelineEmployeeAccessibilityApiCalledId = await this.apiCallAddProject(configJSON.put_method, configJSON.managePipelineEmployeeAccessibilityApiEndPoint + emplyeeToUpddate.id, formData);
    this.handleModelClose();
    this.setState({ manageModelPermssionList: [], manageModelSelectedDept: "", employeeIdToUpdate: "" });
  }
  
  handleSideBar = () => {
    this.setState({
      isSideBarOpen: !this.state.isSideBarOpen
    },
      async () => {
        localStorage.setItem("sidebarvalue", this.state.isSideBarOpen.toString())
      }
    );
  };

  handleDropdown = () => {
    this.setState({
      isDropdownOpen: !this.state.isDropdownOpen
    });
  };

  checkFormValid() {
    Object.keys(this.state.stepOneInputs).forEach((fieldName:string) => {
      this.validateField(fieldName);
    });
  }

  createProject = async () => {
    const Tokenvalue = await getStorageData('token', false)
    const header = {
      "token": Tokenvalue,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createProjectId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createProjectAPI}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    let formDatavalue = new FormData();
   this.imageappendfuction(formDatavalue)
  
    formDatavalue.append("project[project_name]", this.state.stepOneInputs.projectName);
    formDatavalue.append("project[start_date]", this.state.stepOneInputs.projectStartDate);
    formDatavalue.append("project[end_date]", this.state.stepOneInputs.projectEndDate);
    formDatavalue.append("project[description]", this.state.stepOneInputs.projectDescription);
    formDatavalue.append("project[address]", this.state.stepOneInputs.projectAddress);
    formDatavalue.append("project[state]",this.state.stepOneInputs.selectedState.name)
    formDatavalue.append("project[city]",this.state.stepOneInputs.selectedCity.name)
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formDatavalue
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  UpdateProject = async () => {
    const authenticationToken = await getStorageData('token', false)
    const editprojectid=await getStorageData("editproject")
    const header = {
      "token": authenticationToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.UpdateProjectId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateProjectAPI}`+editprojectid
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    ); 
    let formDataUpdate = new FormData();
    
    if(this.state.projectImage)
      {
        formDataUpdate.append("project[image]", this.state.projectImage )
      }
      else{
        formDataUpdate.append("project[image_id]", this.state.imageId )
      }
   
    formDataUpdate.append("project[project_name]", this.state.stepOneInputs.projectName);
    formDataUpdate.append("project[start_date]", this.state.stepOneInputs.projectStartDate);
    formDataUpdate.append("project[end_date]", this.state.stepOneInputs.projectEndDate);
    formDataUpdate.append("project[description]", this.state.stepOneInputs.projectDescription);
    formDataUpdate.append("project[address]", this.state.stepOneInputs.projectAddress);
    formDataUpdate.append("project[state]",this.state.stepOneInputs.selectedState.name)
    formDataUpdate.append("project[city]",this.state.stepOneInputs.selectedCity.name)
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formDataUpdate
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
imageappendfuction=(formDatavalue:any) =>{
  if(this.state.projectImage)
  {
  formDatavalue.append("project[image]", this.state.projectImage )
  }
}

personaldetailmodelopen = (inviteVendorUserId:any) => {
  setStorageData("userInviteVendor", inviteVendorUserId);  
  this.setState({ inviteMemberModal: false })
    
  
  this.setState({personaldetailmodel:true})
}
personaldetailmodelclose = (inviteVendorUserId:any) => {

  
  this.getAllMembersList()
  
  this.setState({personaldetailmodel:false})
}
  getAllDepartmentList = async (searchQuery: string) => {
    const authenticationToken = await getStorageData('token', false)
    const header = {
      "token": authenticationToken,
      "Content-Type": "application/json"

    };
    this.setState({isLoading:true})
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.allDepartmentsID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.projectsAPI}/search?project_id=${this.state.projectId}&search=${searchQuery}`);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  allemployeeList = async () => {

    let token = await getStorageData("token");
    let id = await getStorageData("jobRoleproject_Id");
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.allemployeeApiCalledId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.piplinealineAllEmployeeEndPoint +id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET_Method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

 
  updateDepartments = async () => {
    const authenticationToken = await getStorageData('token', false)
    let id = await getStorageData("jobRoleproject_Id");
    const header = {
      "token": authenticationToken,
      "Content-Type": "application/json"
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updatedepartmentcallidAddProject = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updatedepartmentendpoint+id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    const departmentDataAddProject = this.state.departmentList.map((ele: any) => ({
      id: ele.id,
      name: ele.attributes.name,
      pipeline_department: ele.attributes.pipeline_department,
      level: ele.attributes.level
    }))
    let httpBody = {
      departments: departmentDataAddProject
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  navigateToallJobDetail=async()=>{
    
    this.props.navigation.navigate("ProjectDetailPH")
  }
  handleNextStep = async (index: number) => {
   
    if (index == 1) {
    
      this.checkFormValid()

      let isFormFieldNotEmpty = Object.entries(this.state.stepOneInputs).every(([key, value]: any) => {
        if (key === 'selectedState' || key === "selectedCity") {
          return value.name.length > 0;
        } else {
          return typeof value === 'string' && value.length > 0;
        }
      });
      if (isFormFieldNotEmpty) {
        this.createProject()
        
        return;
      } else {
        return;
      }
    } else if (index == 2) {
       this.updateDepartments()
    
      this.setState({ activeStepIndex: index });
      return
    }
    this.setState({ activeStepIndex: index });
  };

  handleStepOneInputChange = (fieldName: string, value: any) => {
    this.setState({ stepOneInputs: { ...this.state.stepOneInputs, [fieldName]: value } },()=>{
      this.setState({cityList:City.getCitiesOfState(
        this.state.stepOneInputs.selectedState.countryCode,
        this.state.stepOneInputs.selectedState.isoCode
      )})}
      )
  }

  handleUploadImageModal = () => {
    this.setState({
      openUploadProjectImageModal: true
    })
  }
  handleInputFocus = (labelName: string) => {
    const inputLabel = document.getElementById(labelName);
    if (inputLabel) {
      inputLabel.style.color = "#F5C42C";
    }
  };
  handleInputBlur = (labelName: string) => {
    const inputLabel = document.getElementById(labelName);
    if (inputLabel) {
      inputLabel.style.color = "#6D89AF";
    }
  };
  handleaddNewDepartmentModal = () => {
  
    this.updateDepartments()
    this.setState({
      addNewDepartmentModal: !this.state.addNewDepartmentModal
    })
  }

  handleaddNewDepartmentChange = (event: any) => {
    const {value } = event.target;
  
    const isValid = value.trim().length > 0;
    this.setState({
      newDepartmentName: {
        name:event.target.value,
        value: value,
        error: isValid ? false : true,
        errMessage: isValid ? "" : "This field is required",
      },
    });
  };

  handleCloseAddNewDepartmentModal = () => {
    const { newDepartmentName, addNewDepartmentModal } = this.state;
    const isEmpty = newDepartmentName.value.trim() === "";
    this.setState({
      addNewDepartmentModal: isEmpty ? addNewDepartmentModal : !addNewDepartmentModal,
      newDepartmentName: {
        name: newDepartmentName.value,
        value: isEmpty ? '' : newDepartmentName.value,
        error: isEmpty,
        errMessage: isEmpty ? 'This field is required' : '',
      },
    }, () => {
      if (this.state.newDepartmentName.value.length > 1) {
        this.createNewDepartment()
      }
    });
  };
  
  createNewDepartment = async () => {
    const authenticationToken = await getStorageData('token', false)
    const header = {
      "token": authenticationToken,
      "Content-Type": "application/json"
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createDepartmentId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.projectsAPI}?project_id=`+this.state.projectId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    let httpBody = {
      data:{
        department:{
          name: this.state.newDepartmentName.value
        }
      }
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSelectNewDepartmentModal = () => {
    this.setState({
      selectNewDepartmentModal: !this.state.selectNewDepartmentModal
    })
  }

  handleCloseSelectNewDepartment = () => {
    const { selectDepartMent } = this.state;
    if (selectDepartMent.value.value === "Select Dept") {
      this.setState({
        selectDepartMent: {
          ...selectDepartMent,
          error: true,
          errMessage: "Select Department",
        }
      });
    } else {
      this.setState({
        selectNewDepartmentModal: !this.state.selectNewDepartmentModal
      });
    }
  }
  
  handleSMSModalToggle = () => {
    this.setState({
      openSMSDialog: !this.state.openSMSDialog,
    })
  }

  handlePublishProjectModal=()=>{
    this.setState({
      openPublishProjectDialog: !this.state.openPublishProjectDialog
    })
  }

  handleAddNewMemberModal = () => {
    this.setState({
      addNewMemberDetails: {
        fullName: { value: "", error: false, errorMessage: '' },
        phoneNumber: { value: "", error: false, errorMessage: '' },
        selectedDept: { value: { label: "Select Dept", value: "Select Dept" }, error: false, errorMessage: '' },
        selectedRole: { value: { label: "Select Role", value: "Select Role" }, error: false, errorMessage: '' },
      },
      addNewMemberModaltab: 1,
      addNewMemberModal: !this.state.addNewMemberModal
    }, () => {
      
    })
  }
  handleModelOpen = async(type: string) => {
   
    this.setState({ isModelOpen: true, modelType: type },()=>{
    
      this.getDepartmentList()
      this.getpermissionlistapifunction()
     
    });
  }
  getpermissionlistapifunction=async()=>{
    this.getPipelineEmployeePermissionListApiCalledId = await this.apiCallAddProject(configJSON.GET_Method, configJSON.getPipelineEmployeePermissionListApiEndPoint);
  }
  getPermissionsList=async () => {
    const authenticationToken = await getStorageData('token', false)
    const header = {
      "token": authenticationToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.permissionsListID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.permissionsListAPI}`);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getRolesList = async () => {
    const authenticationToken = await getStorageData('token', false)
    const header = {
      "token": authenticationToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.rolesListID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.rolesListAPI}`);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getDepartmentList = async () => {
    const authenticationToken = await getStorageData('token', false)
    const header = {
      "token": authenticationToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.departmentListID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPipelineEmployeeDepartmentListApiEndPoint}?project_id=${this.state.projectId}`);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  AddNewMemberModaltabNext = () => {
    this.setState({ addNewMemberModaltab: 2 })
  }

  AddNewMemberModaltabPrevious = () => {
    this.setState({ addNewMemberModaltab: 1 })
  }
 
  handleFileChange = (event: any) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const imageUrl = URL.createObjectURL(selectedFile);

      this.setState({
        projectImage: selectedFile,
        imagePreviewUrl: imageUrl,
      }, this.handleCloseUploadProjectImageModal);
    }
  }

  shortenFileName(url: string) {
    const pathStartIndex = url.lastIndexOf('/') + 1;
    const pathEndIndex = url.indexOf('?') !== -1 ? url.indexOf('?') : undefined;
    const path = pathEndIndex !== undefined ? url.slice(pathStartIndex, pathEndIndex) : url.slice(pathStartIndex);
    const filenameStartIndex = path.lastIndexOf('/') + 1;
    const filename = path.slice(filenameStartIndex);
    const dotIndex = filename.lastIndexOf('.');
    const truncatedFilename = filename.slice(0, Math.min(dotIndex, 6)) + filename.slice(dotIndex);

    return truncatedFilename;
  }
  
  handleDeleteSelectedImage = () => {
    this.setState({
      projectImage: "",
      imagePreviewUrl: "",
      selectedImageIndex: -1
    });
  }

  handleDefaultImagePopup = () => {
    this.setState({
      opendefaultImageModal: true
    })
  };

  getAllDefaultImageList = async () => {
    const authenticationToken = await getStorageData('token', false)
    const header = {
      "token": authenticationToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.defaultImagesID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.defaultImageAPI}`);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  closeDefaultImagePopup = () => {
    this.setState({
      opendefaultImageModal: false
    })
  };

  handleCloseUploadProjectImageModal = () => {
    this.setState({ openUploadProjectImageModal: false })
  }

  handleInviteMemberModal = async() => {
    this.setState({ inviteMemberModal: !this.state.inviteMemberModal })
    this.vendorListID = await this.getInviteVendorJobRole(configJSON.GET_Method,configJSON.vendorsListAPI + `?page=1&per_page=9`);
  }


  
  getVendorList=async()=>{
    this.vendorListID = await this.getInviteVendorJobRole(configJSON.GET_Method,configJSON.getInviteVendorEndPoint + `?page=1&per_page=9`)
  }

  handlePageChangeInviteVendor =async(event:any,value:number)=>{
    if (this.state.searchinvitevendor) {
      this.getsearchInviteVendor = await this.getInviteVendorJobRole(configJSON.GET_Method, configJSON.getMyJobSearchEndPoint + `=${this.state.searchinvitevendor}&page=${value}`);
      window.scrollTo(0, 0);
    }
    else
    {
    this.vendorListID = await this.getInviteVendorJobRole(configJSON.GET_Method,configJSON.vendorsListAPI + `?page=${value}&per_page=9`);
    window.scrollTo(0, 0);
    }
    this.setState({ currentpage: value });
    
}

filterHousesmyjob = async (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    currentpage: 1,
    searchinvitevendor: event.target.value,
  });
  if (event.target.value)
    this.getsearchInviteVendor = await this.getInviteVendorJobRole(configJSON.GET_Method, configJSON.vendorsListApIsearch + `=${event.target.value}&page=1&per_page=9`);
  else
  this.vendorListID = await this.getInviteVendorJobRole(configJSON.GET_Method,configJSON.vendorsListAPI + `?page=1&per_page=9`);
}

getInviteVendorJobRole = async (method: string, endPoint: string) => {
  const authenticationToken = await getStorageData('token', false)
  const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": authenticationToken
  };
  const requestMessageJobRoleRequestInviteVendor = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  requestMessageJobRoleRequestInviteVendor.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
  );

  requestMessageJobRoleRequestInviteVendor.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );
  requestMessageJobRoleRequestInviteVendor.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
  );
  runEngine.sendMessage(requestMessageJobRoleRequestInviteVendor.id, requestMessageJobRoleRequestInviteVendor);
  return requestMessageJobRoleRequestInviteVendor.messageId;
};
  getAllMembersList = async () => {
    const authenticationToken = await getStorageData('token', false)
    const header = {
      "token": authenticationToken,
      "Content-Type": "application/json"
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.allEmployeeListID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.employeeAPI}project_id=${this.state.projectId}`);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handlePreviousStep = () => {
    this.setState({isLoading:false})
    
    this.updateDepartments()
    this.setState({ activeStepIndex: this.state.activeStepIndex - 1 })
    
  }

  handleInvoiceFileChange = (event: any) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const imageUrl = URL.createObjectURL(selectedFile);

      this.setState({
        invoiceFile: selectedFile,
        invoicePreviewUrl: imageUrl,
      });
    }
  }

  validateField(fieldName: string) {
    const { stepOneInputs } = this.state;
    const value = stepOneInputs[fieldName];
    let label = this.state?.validationStatus[fieldName]?.label;
    switch (fieldName) {
      case 'projectName':
      case 'projectDescription':
      case 'projectAddress':
        this.setState((prevState) => ({
          validationStatus: {
            ...prevState.validationStatus,
            [fieldName]: {
              ...prevState.validationStatus[fieldName],
              valid: !value?.trim(),
              message: !value?.trim() ? `${label} is required` : '',
            },
          },
        }));
        break;

      case 'projectStartDate':
      case 'projectEndDate':
        this.setState((prevState) => ({
          validationStatus: {
            ...prevState.validationStatus,
            [fieldName]: {
              ...prevState.validationStatus[fieldName],
              valid: isNaN(Date.parse(value)),
              message: isNaN(Date.parse(value)) ? `${label} is Invalid` : "",
            },
          },
        }));
        break;

      case 'selectedState':
      case 'selectedCity':
        this.setState((prevState) => ({
          validationStatus: {
            ...prevState.validationStatus,
            [fieldName]: {
              ...prevState.validationStatus[fieldName],
              valid: value?.value === "Select State" || value?.value === "city",
              message: (value?.value === "Select State" || value?.value === "city") ? `${label} is required` : '',
            },
          },
        }));
        break;

      default:
        break;
    }
  }

  handleSavePermissions = (isSubmit = true) => {
   
    this.setState({
      addNewMemberModaltab: 1,
      addNewMemberModal: true,
    });
  };
  handleSaveEmployeeApiCall = async (formData: any) => {
    this.addPipelineEmployeeApiCalledId = await this.apiCallAddProject(configJSON.httpPostMethod, configJSON.addPipelineEmployeeApiEndPoint + this.state.projectId, formData);
  }

  createJobRole = async () => {
    const authenticationToken = await getStorageData('token', false)
    const header = {
      "token": authenticationToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.savePermissionID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_projecttasktracking2/employees/create_job_role?project_id=${this.state.projectId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    let formDatavalue = new FormData();
    const departmentID = this.state.addNewMemberdepartmentList.filter((ele: any) => ele.value == this.state.addNewMemberDetails.selectedDept.value.value)[0]?.id;
    const roleID = this.state.rolesList.filter((ele: any) => ele.value == this.state.addNewMemberDetails.selectedRole.value.value)[0]?.id;
    const permissionIds = this.state.permissionAccess
      .filter((ele: any) => ele.checked && ele.attributes.id)
      .map((ele: any) => ele.attributes.id);
if(this.state.invoiceFile)
{
  formDatavalue.append("employee[photo]", this.state.invoiceFile );

}
   
    formDatavalue.append("employee[full_name]", this.state.addNewMemberDetails.fullName.value);
    formDatavalue.append("employee[full_phone_number]", this.state.countryCodeSelected+this.state.addNewMemberDetails.phoneNumber.value)
    formDatavalue.append("employee[permission_ids]", permissionIds as any);
    formDatavalue.append("employee[project_department_id]", departmentID);

    formDatavalue.append("employee[role_id]", roleID);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formDatavalue
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleCancelPermission = (permissionId: string | number) => {
    const updatedPermissions = this.state.permissionAccess.map((ele: any) => ele.id === permissionId ? { ...ele, checked: !ele.checked } : ele)
    this.setState({ permissionAccess: updatedPermissions })
  };

  handleClickOnImage = (index: number) => {
    this.setState({
      selectedImageIndex: index,
    }, this.handleCloseUploadProjectImageModal
    );
  };
  handleSaveDefaultImage = async() => {
    const selectedImage = this.state.defaultImagesList.filter((ele: any) => ele.id == this.state.selectedImageIndex)[0];

    
    if (selectedImage) {
      const imageUrl = selectedImage.attributes.image;
      const imageName = this.extractImageName(imageUrl);
      const filteredImageName = this.filterImageName(imageName);
      const fileObject = await this.getImageBlob(imageUrl);

      this.setState({
        projectImage:fileObject,
        imagePreviewUrl: imageUrl,
        
      }, () => {
        this.handleCloseUploadProjectImageModal();
        this.closeDefaultImagePopup();
      });
    }
  }
  filterImageName = (name:any) => {
    // Regular expression to keep only alphabetic characters and dot symbol
    return name.replace(/[^a-zA-Z.]/g, '');
  }

  getImageBlob = (url:string) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = 'blob';
  
      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve(xhr.response);
        } else {
          reject(new Error('Failed to load image:' + xhr.statusText));
        }
      };
  
      xhr.onerror = () => {
        reject(new Error('Network error occurred'));
      };
  
      xhr.send();
    });
  };
 



  extractImageName = (url:any) => {
    if (!url) return '';
    const segments = url.split('/');
    return segments[segments.length - 1];
  }

  handleInputChange = (e: any) => {
    const { name, value } = e.target;
      let error = false;
    let errorMessage = '';
  
    if (value.trim() === '') {
      error = true;
      errorMessage = 'This field is required';
    }
  
    if (name === 'phoneNumber' && !/^\d{10}$/.test(value)) {
      error = true;
      errorMessage = 'Please enter 10 digit phone number';
    }
  
    this.setState((prevState) => ({
      addNewMemberDetails: {
        ...prevState.addNewMemberDetails,
        [name]: {
          ...prevState.addNewMemberDetails[name],
          value: value,
          error: error,
          errorMessage: errorMessage,
        },
      },
    }));
  };
  
  handleSelectChange = (selectedOption:any, fieldName:string) => {
    this.setState((prevState) => ({
      addNewMemberDetails: {
        ...prevState.addNewMemberDetails,
        [fieldName]: {
          ...prevState.addNewMemberDetails[fieldName],
          value: selectedOption,
          error: selectedOption.value.trim() === '',
          errorMessage: selectedOption.value.trim() === '' ? 'This field is required' : '',
        },
      },
    }));
  };

  handleSubmit = () => {
    let formIsValid = true;
  
    Object.keys(this.state.addNewMemberDetails).forEach((fieldName) => {
      const field = this.state.addNewMemberDetails[fieldName];
      const isInvalid = 
        field.value.value === 'Select Dept' || 
        field.value.value === 'Select Role' ||
        (typeof field.value === "string" && field.value.trim() === '');
  
      if (isInvalid) {
        formIsValid = false;
        this.setState((prevState) => ({
          addNewMemberDetails: {
            ...prevState.addNewMemberDetails,
            [fieldName]: {
              ...field,
              error: true,
              errorMessage: 'This field is required',
            },
          },
        }));
      }
    });
  
    if (formIsValid) {
      this.handleAddNewMemberModal();
    }
  };
  
  handlenavigateToProjectsPage = () => {
    this.props.navigation.navigate("Projects",);
  }

  navigateToManageProfiles = () => {
    localStorage.setItem("projectData", JSON.stringify(this.state))
    this.props.navigation.navigate("InviteMember",);
  }

  handleGivePermissionModal = (id?: string | number | undefined) => {
    this.setState({ permissionModal: !this.state.permissionModal, activeEmployeeId: id })
  }

  handleSaveGivenPermission = (id?: string | number) => {
    this.handleGivePermissionModal()
  }
 
  handleChangePermissionAccess = (employeeId: any, permissionId: any) => {
    const updatedMemberData = this.state.memberData.map((employee: any) => {
      if (employee.id === employeeId) {
        const updatedPermissions = employee.permissions?.map((permission: any) => {
          return permission.id === permissionId ? { ...permission, checked: !permission.checked } : permission;
        });
        return { ...employee, permissions: updatedPermissions };
      }
      return employee;
    });
  
    this.setState({ memberData: updatedMemberData }, () => {
    });
  };

  handleEditJobRole = (employeeId: string | number) => {
    const employee = this.state.memberData.filter((ele: any) => ele.id == employeeId)[0];
    this.setState({
      addNewMemberModal:true,
      invoicePreviewUrl:employee?.attributes?.photo,
      addNewMemberDetails: {
        fullName: { value: employee.attributes?.full_name,error: false, errorMessage: '' },
        phoneNumber: { value: employee.attributes?.phone_number, error: false, errorMessage: '' },
        selectedDept: { value: { label: employee.attributes?.project_department?.name, value: employee.attributes?.project_department.name }, error: false, errorMessage: '' },
        selectedRole: { value: { label: employee.attributes?.role.name, value: employee.attributes?.role.name }, error: false, errorMessage: '' },
      },
      permissionAccess: employee.attributes?.permissions
    })

  }


  handleDeleteJobRole = async(id:number|string) => {
    let token = await getStorageData("token");

    const header = {
        "Content-Type": configJSON.exampleApiContentType,
        "token": token
    };
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteemployeedataid = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.deletedallemployeeEndPoint+`/${id}`
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.DELETE_Method
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
      }
      handleDeleteJobRoleactive = async(id:number|string) => {
        let token = await getStorageData("token");
    
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.deleteemployeedataidactive = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.deletedallemployeeEndPointactive+`/${id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.DELETE_Method
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
    
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
          }
  handleChangePermission=(permissionId:string|number)=>{
    const updatedPermissions =this.state.permissionAccess.map((permission: any) => {
      return permission.id === permissionId ? { ...permission, checked: !permission.checked } : permission;
    });
    this.setState({permissionAccess:updatedPermissions})
  }


 
    getTokenAndCountryCodeSelected = async(message: Message) => {
     if (getName(MessageEnum.CountryCodeMessage) === message.id) {
       let selectedCode = message.getData(
         getName(MessageEnum.CountyCodeDataMessage)
       );
   
       if (selectedCode !== undefined) {
        await setStorageData("countryname", selectedCode.indexOf("+") > 0
        ? selectedCode.split("+")[1]
        : selectedCode)
         this.setState({
           countryCodeSelected:
             selectedCode.indexOf("+") > 0
               ? selectedCode.split("+")[1]
               : selectedCode
         }, () => {
         
         });
       }
     }
   }

  

  handleModelClose = () => {
    this.setState({ isModelOpen: false, modelType: "" });
  }
  
  updatepiplineddepartment = async() => {

   
    let token = await getStorageData("token");
    let id = await getStorageData("jobRoleproject_Id");
    const header = {
        "Content-Type": configJSON.exampleApiContentType,
        "token": token
    };

    
   

    const httpBody = {
      "departments": this.state.checkedDepartments
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updatedepartmentcallidAddProject = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updatedepartmentendpoint+id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.put_method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  addPipelineEmployeeHandleResponse = (pmjrResponseJson: { data: any, errors: { token: string }[] | { message: string } }) => {
    if (pmjrResponseJson.data) {
      this.setState({
        projectMembersJobRole: [pmjrResponseJson.data, ...this.state.projectMembersJobRole], totalCountPMJR: this.state.totalCountPMJR + 1
      },()=>{
        this.getAllMembersList();
      });
      this.handleModelClose();
      
    } 
    this.setState({ isLoading: false });
  }

  handleSMSInviteResponse = (response: any) => {
    if (response.message) {
      this.setState({
        emailLoginNotification: {
          open: true, type: "success",
          message: response.message
        }
      });
    }

    this.handleSMSModalToggle();
  }

  handleSMSInvite = async () => {
    let token = await getStorageData("token");
    
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": token
    };

    const httpBody ={
      "full_phone_number": this.state.seletedEmp.attributes.full_phone_number,
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postSmsInvite = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postSMSInvite
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  setEmp=(data:any)=>{
    this.setState({seletedEmp:data})
  }

  handleClickOpen = () => {
    this.setState({open:true})
  };

   handleClose = () => {
    this.setState({open:false})
  };
  handleCloseBack = () => {
    this.setState({open:false})
    this.handlenavigateToProjectsPage()
  };
    // Customizable Area End
}