import React, { ChangeEvent } from "react";
import {
    // Customizable Area Start
    Container,
    Box,
    Typography,
    Grid,
    TypographyProps,
    TextField,
    InputAdornment,
    IconButton,
    AppBar
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import { Filtericon } from "../../../components/src/assets";
import Pagination from '@material-ui/lab/Pagination';
import SideBar from "../../../components/src/SideBar";
import Header from "../../../components/src/Header.web";
import Footer from "../../../components/src/Footer";
import { createTheme, ThemeProvider, makeStyles, styled } from "@material-ui/core/styles";
import JobProfileController, { Props } from "./JobProfileController";
import JobCard from "../../../components/src/JobCard";
import MyJobCard from "../../../components/src/MyJobCard";
import Jobfunctionality from "../../../components/src/Jobfunctionality";
import SearchIcon from "@material-ui/icons/Search";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Loader from "../../../components/src/Loader.web";
import Modal from '@material-ui/core/Modal';
import "./fullVisibility.css";
import AppliedJob from "../../../components/src/AppliedJob";
import Raiseinvoicefunctionalitymyjob from "../../../components/src/Raiseinvoicefunctioanlitymyjob";
import SuccessfullmodelRaiseInvoice from "./SuccessfullmodelRaiseInvoice.web";
import AppliedPopup from "./AppliedPopup.web";
import { fontSize } from "../../email-account-login/src/EmailAccountLoginBlock.web";
const theme = createTheme({
    palette: {
        primary: {
            main: "#f5c42c",
            contrastText: "#fff",
        },
    },
});
const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
});

// Customizable Area End
export default class JobProfile extends JobProfileController {


    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    jobMainContent1(sideBarStatus: boolean = this.state.isSideBarOpen) {

        return (
            <>
               
                <Grid style={webStyle.contentSetupJob}>

                <Loader loading={this.state.isLoading}></Loader>
                    < Header
                        handleSideBar={this.handleSideBar}
                        handleDropdown={this.handleDropdown}
                        navigate={this.props.navigation.navigate}
                        sideBarStatus={sideBarStatus}
                        dropDownStatus={this.state.isDropdownOpen}
                    />

                    <Grid style={webStyle.mainContentJob as React.CSSProperties}>
                        <Box sx={webStyle.blockInfoJob}>
                            <Box>
                                <Typography variant="h3" style={webStyle.activeBlockJob as TypographyProps}>Jobs</Typography>
                                <Typography variant="h6" style={webStyle.blockPathJob as TypographyProps}><span style={webStyle.highlightedTextJob} data-test-id="homeLink" onClick={() => this.handleNavigation("JobProile")}>Home</span> / Jobs</Typography>
                            </Box>
                            
                                <Box sx={webStyle.ParentSearchJob}>
                                    <div>
                                        <Box style={webStyle.bellIconBackgroundJob} onClick={this.handleOpen}>
                                            <img src={Filtericon} style={webStyle.FilterDivJob as React.CSSProperties} />
                                        </Box>
    
                                        <Modal
                                            open={this.state.open}
                                            onClose={this.handleClose} // This handles clicking anywhere outside the modal
                                            aria-describedby="simple-modal-description"
                                            aria-labelledby="simple-modal-title"
                                        >
                                            <div style={{ // You can style your modal here
                                                top: '50%',
                                                position: 'absolute',
                                                // Set your desired width
                                                left: '50%',
                                                backgroundColor: 'white',
                                                borderRadius: '20px',
                                                transform: 'translate(-50%, -50%)',
                                                
                                             
    
                                            }}>
                                                {/* Modal content goes here */}
                                                <Jobfunctionality data="" experience={this.state.experience} cityname={this.state.city} department={this.state.department} RoleType={this.state.RoleType} stateindex={this.state.experiencevalue} handleOptionChange={this.handleOptionChange} departmentvalue={this.state.departmentvalue} roletypevalue={this.state.roletypevalue} cityvalue={this.state.cityvalue} handleOptionChangedepartment={this.handleOptionChangedepartment} handleOptionChangecity={this.handleOptionChangecity} handleOptionChangeroletype={this.handleOptionChangeroletype} handleClose={this.handleClose} sortApiCallForAllProduction={this.sortApiCallForAllProduction} ClearDataValues={this.ClearDataValues} />
                                            </div>
                                        </Modal>
                                    </div>
    {
        this.state.TabValue==0 &&
            <Box sx={webStyle.houseSearchBoxJob} >
            <SearchTextField
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <IconButton style={webStyle.iconButtonJob}>
                                <SearchIcon htmlColor="#6D89AF" style={webStyle.searchIconJob} />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                variant="outlined"
                fullWidth
                placeholder={"Search for Jobs"}
                onChange={(event: ChangeEvent<HTMLInputElement>) => this.filterHouses(event)} data-testid='search' 
                />
        </Box>
        
    }
     {
        this.state.TabValue==1&&(
            <Box sx={webStyle.houseSearchBoxmyJob}>
            <TextField
                data-test-id="houseSearchInput"

                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <IconButton style={webStyle.iconButtonJobmyjob}>
                                <SearchIcon htmlColor="#6D89AF" style={webStyle.searchIconJob} />
                            </IconButton>
                        </InputAdornment>
                    ),
                    style: {
                       
                        height: "40px",
                        color: "#6d89af",
                        fontFamily: "Urbanist", 
                        borderRadius: "35px",
                        backgroundColor: "#F8FAFE",
                        padding: "0px",
                        fontSize: "14px",
                        fontWeight: 500,
                        letterSpacing: "0.25454545px"
                    } as React.CSSProperties
                }}
                variant="outlined"
                fullWidth
                placeholder={"Search Jobs"}
                onChange={(event: ChangeEvent<HTMLInputElement>) => this.filterHousesmyjob(event)} data-testid='search1' />
        </Box>
        )
    }
     {
        this.state.TabValue==2&&(
            <Box sx={webStyle.houseSearchBoxappliedJob}>
            <TextField
                data-test-id="houseSearchInput"

                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <IconButton style={webStyle.iconButtonJobapplied}>
                                <SearchIcon htmlColor="#6D89AF" style={webStyle.searchIconJob} />
                            </IconButton>
                        </InputAdornment>
                    ),
                    style: {
                        padding: "0px",
                        height: "40px",
                        color: "#6d89af",
                        fontFamily: "Urbanist", 
                        borderRadius: "35px",
                        backgroundColor: "#F8FAFE",
                        fontSize: "14px",
                        fontWeight: 500,
                        letterSpacing: "0.25454545px"
                    } as React.CSSProperties
                }}
                variant="outlined"
                fullWidth
                placeholder={"Search Jobs"}
                onChange={(event: ChangeEvent<HTMLInputElement>) => this.filterHousesappliedjob(event)} data-testid='search2' />
        </Box>
        )
    }

                                 
                                </Box>
                        </Box>
                        <AppBar
              position="static"
              className="appbar app_bar"
              color="transparent"
            >
                        <Tabs
                            value={this.state.TabValue}
                            onChange={this.handleChangeTab}
                            indicatorColor="primary"
                            textColor="primary"
                            style={{background:"white" , zIndex: 2}}
                        >
                            <Tab className={this.state.TabValue == 0 ? "active_btn" : "non_active_btn"} label="All Jobs" />
                            <Tab className={this.state.TabValue == 1 ? "active_btn" : "non_active_btn"} label="My Jobs" />
                            <Tab className={this.state.TabValue == 2 ? "active_btn" : "non_active_btn"} label="Applied Jobs" />
                        </Tabs>
                        </AppBar>

                        {this.state.TabValue == 0 && (
                                

                            <GridStyled  container spacing={3} >
                                {this.state.allJobData?.map((item: any, index: any) => (
                                    <Grid item key={`${item}_alljob_card`} xl={3} lg={4} md={6} sm={12} xs={12} onClick={() => this.navigateToallJobDetail(item.id, item.attributes.project.account_id)}  >
                                        <Box sx={webStyle.headerSearchParentJob}>
                                            <JobCard data={item} />
                                        </Box>
                                    </Grid>
                                ))}

                                {this.state.count ?
                                    (<Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Pagination style={{ marginTop: "30px", justifyContent: "center", display: "flex" }} data-test-id="pagination" count={Math.ceil((this.state.count) / 9)} page={this.state.currentpage} onChange={this.handlePageChange} />
                                        </Grid>
                                    </Grid>)
                                    : null}
                            </GridStyled>

                        )


                        } {this.state.TabValue == 1 && (

                            <Grid container spacing={3} style={{paddingBottom:'32px'}} >
                                {this.state.myJobData?.map((item: any, index: any) => (
                                    <Grid item xl={3} lg={4} md={6} sm={12} xs={12} onClick={(e:any) => this.navigateToMyJobDetail(e,item.id, item.attributes.project.data.attributes.account_id,item.attributes.project.data.attributes.project_name)}  >
                                        <Box sx={webStyle.headerSearchParentJob}>
                                            <MyJobCard data={item}  handleClose={this.handleClose1} open={this.state.open1} handleOpen1={this.handleOpen1} leaveProject={this.leaveProject} handleViewinvoice={this.handleViewinvoice}/>
                                        </Box>
                                    </Grid>
                                ))}
                                 {this.state.count2 ?
                                    (<Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Pagination data-test-id="pagination" style={{ marginTop: "30px", justifyContent: "center", display: "flex" }} count={Math.ceil((this.state.count2) / 9)} page={this.state.currentpage2} onChange={this.handlePageChange2} />
                                        </Grid>
                                    </Grid>)
                                    : null}

                            </Grid>
                        )}

                        {this.state.TabValue !== 0 && this.state.TabValue !== 1 && (
                            <Grid container spacing={3} style={{paddingBottom:'32px'}} >
                                {this.state.appliedJobData
        // Reverse the order of the array
        .map((item: any, index: any) => (
          <Grid
            item
            key={`${item}_applied_card`}
            xl={3}
            lg={4}
            md={6}
            sm={12}
            xs={12}
            onClick={() => this.navigateToAppliedJobDetail(item.id, item.attributes.project.data.attributes.account_id,item.attributes.vendor_job_request_id,item.attributes.job_request_date)}
          >
            <Box sx={webStyle.headerSearchParentJob}>
              <AppliedJob data={item} withdrawJob={this.withdrawJob} />
            </Box>
          </Grid>
        ))}
                                 {this.state.count1 ?
                                    (<Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Pagination style={{ justifyContent: "center", marginTop: "30px", display: "flex" }} data-test-id="pagination" count={Math.ceil((this.state.count1) / 9)} page={this.state.currentpage1} onChange={this.handlePageChange1} />
                                        </Grid>
                                    </Grid>)
                                    : null}
                            </Grid>
                        )}                    
                      
                    </Grid>
                    <Footer  />
                </Grid >
            </>
        )
    }
    // Customizable Area End

    render() {
        return (

            <ThemeProvider theme={theme}>
                <Container style={webStyle.webparentDivJob}>
                    {
                        this.state.isSideBarOpen ?
                            <Grid container spacing={0}>
                                <Grid item xs={3} style={webStyle.websidebarParentJob}>
                                    <SideBar
                                        userData={{ age: 25 }}
                                        activeMenuItem={"Jobs"}
                                        navigate={this.props.navigation.navigate}
                                        handleSideBar={this.handleSideBar} />
                                </Grid>
                                <Grid item xs={9}>{this.jobMainContent1()}</Grid>
                            </Grid> :
                            <Grid item xs={12}>{this.jobMainContent1()}</Grid>
                    }
                </Container>
                <Modal
                    open={this.state.open1}
                    onClose={(e: any) => this.handleClose1(e)}
                >
                    <div style={{ 
                        position: 'absolute',
                        left: '50%',
                        backgroundColor: 'white',
                        transform: 'translate(-50%, -50%)',
                        borderRadius: '20px',
                        top: '50%',
                    }}>

<Raiseinvoicefunctionalitymyjob data={""} handleClose1={this.handleClose1} invoiceselect={this.state.invoiceselect} handleChangeinvoice={this.handleChangeinvoice} handleChangeradio={this.handleChangeradio} selectinvoicetype={this.state.selectinvoicetype} handleChangeName={this.handleChangeName} selectinvoicename={this.state.selectinvoicename} selectinvoicedetail={this.state.selectinvoicedetail} handleChangeDetail={this.handleChangeDetail} selectproject={this.state.selectproject} handleChangeProject={this.handleChangeProject} handleChangeNo={this.handleChangeNo} selectinvoiceno={this.state.selectinvoiceno} handleChangeAmount={this.handleChangeAmount} selectinvoiceamount={this.state.selectinvoiceamount} handleImage={this.handleImage} image={this.state.image} raiseinvoiceData={this.raiseinvoiceData} dialogOpen={this.state.dialogOpen} handleDialogClickOpen={this.handleDialogClickOpen} handleDialogClose={this.handleDialogClose}/>
                    </div>
                </Modal>
                {this.state.isModelOpen &&this.state.modelType === "successModel" ? <SuccessfullmodelRaiseInvoice navigation={this.props.navigation} open={this.state.isModelOpen} handleClose={this.handleModelClose} handlevalue={this.handledatavalue} raiseinvoicedetail={this.state.raiseinvoicedetail} /> : null}
                { this.state.PopupMessage1 ? <AppliedPopup navigation={this.props.navigation} open={this.state.PopupMessage1} handleClose={this.handlPopClose1} message={this.state.messageshow1}/> : null}
            </ThemeProvider>

        );
    }
}

// Customizable Area Start




const SearchTextField = styled(TextField)({
    maxWidth:"100%",
    "& .MuiOutlinedInput-adornedStart":{
        padding: "0px",
        height: "40px",
        color: "#6d89af",
        fontFamily: "Urbanist", 
        backgroundColor: "#F8FAFE",
        fontSize: "14px",
        fontWeight: 500,
        letterSpacing: "0.25454545px",
        borderRadius: "35px",
        },
          "@media(max-width: 415px)": {
         width:"78%"
    },
})

const GridStyled = styled(Grid)({
    paddingBottom:'32px',
    maxHeight:"72vh",
    "@media(max-width: 500px)": {
        maxHeight:"60vh",
   },
})

const webStyle = {

    FilterDivJob: {
        height: "15px",

    },
    webparentDivJob: {
        maxWidth: "none",
        padding: "0px",

    },
    iconButtonJob: {
        margin: "0px",
        padding: "0px",
        marginLeft: "15px"
    },
    iconButtonJobmyjob: {
        margin: "0px",
        padding: "0px",
        marginLeft: "15px"
    },
    iconButtonJobapplied: {
        margin: "0px",
        padding: "0px",
        marginLeft: "15px"
    },
    houseSearchBoxJob: {
        minWidth: "260px",
    },
    houseSearchBoxmyJob: {
        minWidth: "260px",
    },
    houseSearchBoxappliedJob: {
        minWidth: "260px",
    },
    ParentSearchJob: {
        display: "flex",
        gap: "10px"

    },
    bellIconBackgroundJob: {
        width: "44px",
        height: "44px",
        backgroundColor: "#F8FAFE",
        border: "1px solid #DADADA",
        borderRadius: "22px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        marginLeft: "10px"
    },

    websidebarParentJob: {
        minHeight: "100vh",
        maxWidth: "23%"
    },



    contentSetupJob: {

        borderLeft: "1px solid #E3EAF4",
    },




    mainContentJob: {
        width: "100%",
        backgroundColor: "#F8FAFE",
        boxSizing: "border-box",
        padding: "24px",
        minHeight:"100vh",
        height:"850px",
    },

    highlightedTextJob: {
        color: "#F5C42C",
        textDecoration: 'underline',
        textDecorationColor: "#F5C42C",
        fontWeight: 600
    },

    headerSearchBoxJob: {
        display: "flex",
        justifyContent: "start",
        align: "end",
        flexDirection: "row",
        flexWrap: "wrap",
        paddingBottom: '32px',
        width:'100%'

    },

    // headerSearchBoxJob: {
    //     display: "flex",
    //     justifyContent: "start",
    //     align: "end",
    //     flexDirection: "row",
    //     flexWrap: "wrap",
    //     paddingBottom: '32px'

    // },

    headerSearchParentJob: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '16px'
    },

    searchIconJob: {
        height: "20px",
        width: "20px",
    },


    blockInfoJob: {
        display: 'flex',
        justifyContent: 'space-between'
    },

    activeBlockJob: {
        lineHeight: "34px",
        fontSize: "28px",
        fontWeight: "700",
        letterSpacing: "0.31111112px",
        display: "flex",
        flexDirection: "row",
        alignItems: 'center',
        gap: '12px',
        marginBottom: '8px'
    },

    blockPathJob: {
        fontSize: "14px",
        letterSpacing: "1px",
        color: "#6D89AF",
        marginBottom: "15px",
        fontWeight: 500
    },

};
// Customizable Area End
