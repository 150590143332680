import React, { ReactComponentElement } from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  TypographyProps,
  Link, AppBar, Tabs, Tab, Stepper, Step, StepLabel, StepIconProps, InputAdornment, Select, MenuItem, IconButton, Button, FormLabel,InputLabel,FormControl,Checkbox, TextField
} from "@material-ui/core";
import CancelIcon from '@material-ui/icons/Cancel';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import StepConnector from '@material-ui/core/StepConnector';
import { createTheme, styled, ThemeProvider, withStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import CreateNewStudioController, { Props } from "./CreateNewStudioController";
import { Raiseinvoicecertificate, uploadinvoice } from "./assets";
import { CustomTextField } from "../../blocks/email-account-login/src/CustomButton";

import CustomNotification from "../../components/src/CustomNotification"
import { MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers';
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector.web";
import DateFnsUtils from '@date-io/date-fns';
import Autocomplete from '@material-ui/lab/Autocomplete'
import Chip from "@material-ui/core";
import { PropTypes } from "@material-ui/core";
import Downshift from "downshift";
import TagsInput from "./InputTag";
const images = require("./assets");

const themeStyle = createTheme({
  palette: {
    primary: {
      main: "#F5C42C",
      contrastText: "#fff"
    }
  },
  overrides: {
    MuiSelect: {
      select: {
        "&:focus": {
          border: "1px solid #F5C42C",
        }
      }
    }
  }
});
export const CustomInputlabelEmail = withStyles({
  root: {
    fontFamily: 'Urbanist',
    color: '#f5c42c',
    fontWeight: 600,
    "@media (max-width : 1440px)": {
      fontSize: '14px'
    },
    "@media (min-width : 1441px)": {
      fontSize: '14px',
      lineHeight: "14px",
      paddingBottom: "6px",
      paddingTop: "14px"

    }
  },
})(InputLabel);


export const CustomButton = withStyles({
  root: {
    "& .MuiButton-label": {
      justifyContent: "center"
    },
    fontFamily: 'Urbanist',
    fontWeight: 700,
    lineHeight: '3rem',
    color: '#FFFFFF',
    width: '100%',
    textTransform: 'none',
    boxShadow: 'none',
    borderRadius: '14px',
    cursor: 'hand',
    "&.MuiButton-contained": {
      backgroundColor: 'black',
      color: '#FFFFFF',
      '& :hover': {
        background: 'black'
      }
    },
    "&.MuiButton-outlined": {
      "& : hover": {
        backgroundColor: 'black'
      }
    },
    "& : hover": {
      boxShadow: 'none',
    },
  }
})(Button);

const CustomPHBackButtonBox = styled(CustomButton)({
  background: "#ffffff",
  border: "1px solid #17181d",
  '& .MuiButton-label': {
    fontSize: 14,
    color: '#17181d',
    fontWeight: 600,
    width: 163,
    height: 48,
  }
});



export const ColorlibConnector = styled(StepConnector)(() => ({
  '&.MuiStepConnector-alternativeLabel': {
    top: 16,
    left: 'calc(-50% - 16px)',
    right: 'calc(50% - 16px)',
  },
  '&.MuiStepConnector-active': {
    '& .MuiStepConnector-line': {
      borderColor: '#f5c42c',
    },
  },
  '&.MuiStepConnector-completed': {
    '& .MuiStepConnector-line': {
      borderColor: '#f5c42c',
    },
  },
  '& .MuiStepConnector-line': {
    borderColor: '#e3eaf4',
  },
}));

export const ColorlibStepIconRoot = styled('div')(() => ({
  backgroundColor: '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
}));

const CustomPHSignUPButtonBox = styled(CustomButton)({
  '& .MuiButton-label': {
    fontSize: 14,
    color: '#ffffff',
    height: 48,
    width: 163,
    fontWeight: 500,
  }
});


const PatentGrid = styled(Grid)({
  '& input::placeholder': {
    color: '#6d89af',
    fontFamily: 'Urbanist',
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '0.28px'
  },

  '& textarea::placeholder': {
    color: '#6d89af',
    fontFamily: 'Urbanist',
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '0.28px'
  },
  "& .MuiInput-underline::after": {
    borderBottom: 'unset'
  },
  "@media(max-width: 500px)": {
    marginRight: "0px",
  },
  "& .MuiInput-underline::before": {
    content: 'none'
  },
  "& .MuiInputLabel-animated.Mui-focused": {
    transform: 'unset',
    color: 'transparent'
  },

})

const CustomCSSOfTextField = withStyles({
  root: {
    //backgroundColor: 'white',
    borderColor: "#f5c42c",
    "& .MuiOutlinedInput-root": {
      "& fieldset": { borderColor: "#E3EAF4" },
      "&:hover fieldset": { borderColor: "#f5c42c" },
      "&.Mui-focused fieldset": {
        backgroundColor: 'rgba(245,196,44,0.04)',
        borderColor: "#f5c42c",
      },
      borderRadius: "14px",
      fontWeight: 400,
      color: '#323232',
      "& .MuiOutlinedInput-input": {
        color: '#17181D',
        fontWeight: 600,
        fontSize: '14px',
        borderColor: '#e3eaf4',
        lineHeight: '19px',
      }
    },
    "& .MuiOutlinedInput-root.Mui-disabled": {
      "& fieldset": {
        border: "none"
      },
      "&:hover fieldset": {
        border: "none"
      },
      "&.Mui-focused fieldset": {
        border: "none",
        backgroundColor: '#f8fafe'
      },
      border: "none",
      backgroundColor: '#f8fafe',
      color: '#18272a',
      borderRadius: "14px",
      "& .MuiOutlinedInput-input": {
        fontWeight: 600,
        color: '#18272a',
        lineHeight: '19px',
        border: "none",
        fontSize: '14px',
      }
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#f5c42c"
    },
    "& .MuiInputBase-input::placeholder": {
      color: '#6d89af',
      fontWeight: 500,
      fontFamily: 'Urbanist',
    },
    '&.timingbox > .MuiOutlinedInput-root': {
      paddingRight: '0px !important'
    },
    '&.timingbox .MuiInputAdornment-root.MuiInputAdornment-positionEnd fieldset': {
      border: 'none !important',
    },
    '&.pricebox input': {
      maxWidth: '75%',
      width: '100%'
    }
  }
})(CustomTextField);


export default class CreateNewStudio extends CreateNewStudioController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  vendorProfileRenderErrorMessage(errorType: boolean, errorMessage: string) {
    return <>{errorType ? <Typography style={{ color: 'red', fontFamily: 'Urbanist', fontSize: '13px', fontWeight: 600, lineHeight: '18px', letterSpacing: '0.4px' }}>{errorMessage}</Typography> : null}</>
  }

  VendorProfileColorlibStepIcon(props: StepIconProps) {
    const { active, completed } = props;
    const vendorProfileiIcons: { [index: string]: any } = { 1: "01", 2: "02" };
    const vendorProfileGetclassName = () => {
      if (active) { return "activeIcon" }
      else if (completed) { return "completedIcon" }
      else { return "disbaleIcon" }
    }

    return (
      <ColorlibStepIconRoot className={`stepIcon ${vendorProfileGetclassName()}`}>
        {vendorProfileiIcons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  // Customizable Area End
  renderVendorProfileStepOneView() {
    return <>
      <Box style={{ paddingLeft: '20px', paddingRight: '20px', height: '395px', overflowY: 'scroll' }}>
        {


          this.state.image ? (
            <Box style={webStyle.uploadinvoicereceiptdiv}>
              <Box style={{ display: 'flex', gap: "20px", alignItems: 'center' }}>
                <Box style={webStyle.invoiceuploadicon as React.CSSProperties}>
                  <input
                    type="file"
                    accept='image/*'

                    style={{
                      opacity: 0,
                      position: 'absolute',
                      width: '30px',
                      height: '30px',
                    }}
                    onChange={this.handleImage}
                  />

                  <img src={uploadinvoice} alt="" style={webStyle.uploadicon} />
                </Box>
                <Box style={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>
                  <Box style={webStyle.IMAGEBOX}>
                    <img src={Raiseinvoicecertificate} alt="" style={webStyle.uploadimage} />
                  </Box>
                  {
                    this.state.imagedataimage == "previousimage"?(
                      <Box style={{ fontSize: '11px', fontWeight: 600, color: '#17181D', fontFamily: 'urbanist' }}>{this.state.imagename}</Box>
                    ):
                    (
                      <Box style={{ fontSize: '11px', fontWeight: 600, color: '#17181D', fontFamily: 'urbanist' }}>{this.state.image.name}</Box>
                    )

                  }
                  
                  
                </Box>
              </Box>

            </Box>
          ) : (
            <Box style={webStyle.uploadinvoicereceiptdiv}>
              <Box style={webStyle.invoiceuploadicon}>
                <input
                  type="file"
                  accept='image/*'

                  style={{
                    opacity: 0,
                    position: 'absolute',
                    width: '30px',
                    height: '30px',
                  }}
                  onChange={this.handleImage}
                />
                <img src={uploadinvoice} alt="" style={webStyle.uploadicon} />
              </Box>
              <Box style={webStyle.invoiceheadingandsubheading}>
                <Box style={webStyle.invoiceheading as React.CSSProperties}>
                  Upload Studio Image
                </Box>
                <Box style={webStyle.invoicesubheading as React.CSSProperties}>
                  (.png, .jpg, .jpeg, .pdf)
                </Box>
              </Box>
         
            </Box>
          
          )
         
        }
          {  this.state.image?
          
          null:this.vendorProfileRenderErrorMessage(this.state.vendorProfileDataError.imageissue.require, "Studio Image is required")}
        <Box style={{ display: 'flex', gap: '24px', paddingBottom: '24px',marginTop:'24px' }}>
          <Box style={{ width: '100%' }}>
              <CustomInputlabelEmail id="fullnameOfStudiotype" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px" }}>Studio Type</CustomInputlabelEmail> 
          


<TagsInput
    
        selectedTags={this.handleSelecetedTags}
        fullWidth
        variant="outlined"
        id="fullnameOfStudiotype"
        name="tags"
        studiotype={this.state.studiotype}
        placeholder="Type and select requirements..."
        tags={this.state.studiotype}
      />
       {this.vendorProfileRenderErrorMessage(this.state.vendorProfileDataError.studiotype.require, "Studio Type is required")}


          </Box>
        
          <Box style={{ width: '100%' }}>
            <CustomInputlabelEmail id="fullnameOfStudioPH" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px" }}>Studio Name</CustomInputlabelEmail>
            <Box>
              <CustomCSSOfTextField
                data-test-id="fullnameOfStudioPH"
                type={'text'} id="outlined-basic"
                onFocus={() => {
                  this.handleInputFocus("fullnameOfStudioPH");
                }}
                onBlur={() => {
                  this.handleInputBlur("fullnameOfStudioPH");
                }}

                placeholder="Enter the studio name"
                variant="outlined"
                className="email_input"
                value={this.state.vendorProfileData.fullname}
                onChange={this.handleChangeName}
              />
              {this.vendorProfileRenderErrorMessage(this.state.vendorProfileDataError.fullname.require, "Studio Name is required")}

            </Box>

          </Box>

        </Box>

        <Box style={{ display: 'flex', gap: '24px', paddingBottom: '24px' }}>
          <Box style={{ width: '100%' }}>
            <CustomInputlabelEmail id="pricehour" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px" }}>Price</CustomInputlabelEmail>
            <Box style={{
              position: 'relative'
            }}>
              <Box style={{ position: 'absolute', top: 16, right: 10, borderLeft: '1px solid #e3eaf4', paddingLeft: '10px', color: "#6d89af", fontFamily: 'urbanist', fontSize: '14px', fontWeight: 600, letterSpacing: '0.2px' }}>Hour</Box>
              <CustomCSSOfTextField

                data-test-id="fullnameOfVendorProfileInput"
                type={'text'} id="outlined-basic"
                onFocus={() => {
                  this.handleInputFocus("pricehour");
                }}
                onBlur={() => {
                  this.handleInputBlur("pricehour");
                }}
                value={this.state.vendorProfileData.pricehour}
                variant="outlined"
                className="email_input pricebox"
                onChange={this.handleChangepricehour}

              />
              {this.vendorProfileRenderErrorMessage(this.state.vendorProfileDataError.pricehour.require, "Price is required")}

            </Box>

          </Box>
          <Box style={{ width: '100%' }}>
            <CustomInputlabelEmail id="priceday" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px" }}>Price</CustomInputlabelEmail>
            <Box style={{
              position: 'relative'
            }}>
              <Box style={{ position: 'absolute', top: 16, right: 10, borderLeft: '1px solid #e3eaf4', paddingLeft: '10px', color: "#6d89af", fontFamily: 'urbanist', fontSize: '14px', fontWeight: 600, letterSpacing: '0.2px' }}>Day</Box>
              <CustomCSSOfTextField

                data-test-id="fullnameOfVendorProfileInput"
                type={'text'} id="outlined-basic"
                onFocus={() => {
                  this.handleInputFocus("priceday");
                }}
                onBlur={() => {
                  this.handleInputBlur("priceday");
                }}
                value={this.state.vendorProfileData.priceday}
                variant="outlined"
                className="email_input"
                onChange={this.handleChangepriceday}
              />
              {this.vendorProfileRenderErrorMessage(this.state.vendorProfileDataError.priceday.require, "Price is required")}
            </Box>

          </Box>

        </Box>
        <Box style={{ display: 'flex', gap: '24px', paddingBottom: '24px' }}>
          <Box style={{ width: '100%' }}>
            <CustomInputlabelEmail id="time" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px" }}>Studio Open Timing</CustomInputlabelEmail>
            <Box style={{
              position: 'relative'
            }}>

              <div>

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container alignItems="center">
          <KeyboardTimePicker
            margin="normal"
            id="open-time-picker"
            format="hh:mm"
            value={this.state.vendorProfileData['account[time]']}
            onChange={this.handleTimeChangeOpen}
            TextFieldComponent={(props) => (
              <CustomCSSOfTextField
                className="timingbox"
                {...props}
                variant="outlined"
                margin="normal"
                onFocus={() => this.handleInputFocus("time")}
                onBlur={() => this.handleInputBlur("time")}
              />
            )}
            keyboardIcon={
              <Select
                value={this.state.amPmOpen}
                onChange={this.handleAmPmChangeOpen}
                variant="outlined"
                onFocus={() => this.handleInputFocus("time")}
                onBlur={() => this.handleInputBlur("time")}
                style={{ minWidth: 60 }} // Add styling to match design
              >
                <MenuItem value="AM">AM</MenuItem>
                <MenuItem value="PM">PM</MenuItem>
              </Select>
            }
            onFocus={() => this.handleInputFocus("time")}
            onBlur={() => this.handleInputBlur("time")}
          />
          {this.vendorProfileRenderErrorMessage(this.state.vendorProfileDataError["account[time]"].require, "Time is required")}
        </Grid>
      </MuiPickersUtilsProvider>

              </div>
            </Box>

          </Box>
          <Box style={{ width: '100%' }}>
            <CustomInputlabelEmail id="timeclose" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px" }}>Studio Close Timing</CustomInputlabelEmail>
            <Box>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <Grid container alignItems="center">
      <KeyboardTimePicker
        margin="normal"
        id="time-close-picker"
        format="hh:mm"
        value={this.state.vendorProfileData['account[timeclose]']}
        onChange={this.handleTimeChangeclose}
        TextFieldComponent={(props) => (
          <CustomCSSOfTextField
            className="timingbox"
            {...props}
            variant="outlined"
            margin="normal"
            onFocus={() => this.handleInputFocus("timeclose")}
            onBlur={() => this.handleInputBlur("timeclose")}
          />
        )}
        keyboardIcon={
          <Select
            value={this.state.amPmClose}
            onChange={this.handleAmPmChangeClose}
            variant="outlined"
            onFocus={() => this.handleInputFocus("timeclose")}
            onBlur={() => this.handleInputBlur("timeclose")}
            style={{ minWidth: 60 }} // Optional styling to ensure proper fit
          >
            <MenuItem value="AM">AM</MenuItem>
            <MenuItem value="PM">PM</MenuItem>
          </Select>
        }
        onFocus={() => this.handleInputFocus("timeclose")}
        onBlur={() => this.handleInputBlur("timeclose")}
      />
      {this.vendorProfileRenderErrorMessage(this.state.vendorProfileDataError["account[timeclose]"].require, "Time is required")}
    </Grid>
  </MuiPickersUtilsProvider>
            </Box>
          </Box>
        </Box>
        <Box style={{ display: 'flex', gap: '24px', paddingBottom: '26px' }}>
          <Box style={{ width: '100%' }}>
            <CustomInputlabelEmail id="contact" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px" }}>Studio Contact Number</CustomInputlabelEmail>
            <Box>
                {/* <CustomCSSOfTextField

              data-test-id="fullnameOfVendorProfileInput"
              type={'text'} id="outlined-basic"
              onFocus={() => {
                this.handleInputFocus("contact");
              }}
              onBlur={() => {
                this.handleInputBlur("contact");
              }}
              value={this.state.vendorProfileData.contact}
              placeholder="Enter the studio contact no."
              variant="outlined"
              className="email_input"
              onChange={this.handleChangecontact}
            /> */}
             <CustomCSSOfTextField
                                                data-test-id="phoneNumberInput"
                                                type={'text'} id="outlined-basic" variant="outlined" placeholder="Enter studio contact no."
                                                InputProps={{
                            
                    
                    startAdornment: (
                      <InputAdornment position="start" style={{ cursor: 'hand',marginRight:'40px' }} className="phone_input_adornment_box">
                        <CountryCodeSelector
                          navigation={this.props.navigationvalue}
                          id={"CountryCodeSelector"}
                          style={{ width: "80px",
                          
                           } }
                          disable={false}
                          allowPropChange={true}
                          value={this.state.countryCodeSelected}
                          getCountryName={this.getCountryName}
                          
                        />
                        <Typography style={{ marginLeft: '9px', color: '#17181d' }}> +{this.state.countryCodeSelected}</Typography>
                        <Box style={{ borderLeft: '1px solid #6d89af', height: '23px', marginLeft: '12px' } as React.CSSProperties}></Box>
                      </InputAdornment>
                    ),
                  }}
  
                                                onFocus={() => {
                                                    this.handleInputFocus("contact");
                                                }}
                                                onBlur={() => {
                                                    this.handleInputBlur("contact");
                                                }}
                                                name="phoneNumber"
                                                value={this.state.vendorProfileData.contact}
                                                onChange={this.handleChangecontact}
                                                className="email_input"
                                            />

{this.state.phoneNumberError.invalid ? <Typography style={{ color: 'red' }}>Phone number is invalid</Typography> : null}
          {this.state.phoneNumberError.require ? <Typography style={{ color: 'red' }}>Phone number is require</Typography> : null}
            </Box>

          </Box>
          <Box style={{ width: '100%' }}>
            <CustomInputlabelEmail id="email" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px" }}>Studio Email ID</CustomInputlabelEmail>
            <Box>
              <CustomCSSOfTextField
                data-test-id="fullnameOfVendorProfileInput"
                type={'text'} id="outlined-basic"
                onFocus={() => {
                  this.handleInputFocus("email");
                }}
                onBlur={() => {
                  this.handleInputBlur("email");
                }}

                placeholder="Enter studio email ID"
                variant="outlined"
                className="email_input"
                value={this.state.vendorProfileData.email}
                onChange={this.setEmail}

              />
              {this.vendorProfileRenderErrorMessage(this.state.vendorProfileDataError.email.require, "Please Enter  valid email ID")}

            </Box>

          </Box>

        </Box>
        <Box style={{ display: 'flex', gap: '24px', paddingBottom: '21px' }}>
          <Box style={{ width: '100%' }}>
            <CustomInputlabelEmail id="Description" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px" }}>Studio Description</CustomInputlabelEmail>
            <Box>
              <CustomCSSOfTextField
                multiline
                rows={2}
                data-test-id="fullnameOfVendorProfileInput"
                type={'text'} id="outlined-basic"
                onFocus={() => {
                  this.handleInputFocus("Description");
                }}
                onBlur={() => {
                  this.handleInputBlur("Description");
                }}
                value={this.state.vendorProfileData.Description}
                placeholder="Enter about the studio"
                variant="outlined"
                className="email_input"
                onChange={this.handleChangeDescription}
              />
              {this.vendorProfileRenderErrorMessage(this.state.vendorProfileDataError.Description.require, "Description is required")}

            </Box>

          </Box>
          <Box style={{ width: '100%' }}>
            <CustomInputlabelEmail id="Address" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px" }}>Studio Address</CustomInputlabelEmail>
            <Box>
              <CustomCSSOfTextField
                multiline
                rows={2}
                data-test-id="fullnameOfVendorProfileInput"
                type={'text'} id="outlined-basic"
                onFocus={() => {
                  this.handleInputFocus("Address");
                }}
                onBlur={() => {
                  this.handleInputBlur("Address");
                }}
                value={this.state.vendorProfileData.Address}
                placeholder="Enter the address of studio Location"
                variant="outlined"
                className="email_input"
                onChange={this.handleChangeAddress}
              />
              {this.vendorProfileRenderErrorMessage(this.state.vendorProfileDataError.Address.require, "Address is required")}

            </Box>

          </Box>

        </Box>
        <Box style={{ display: 'flex', gap: '24px', paddingBottom: '24px' }}>
        <Box style={{ width: '100%' }}>
        <CustomInputlabelEmail id="stateDropdown" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px" }}>State</CustomInputlabelEmail>
          
          <Grid className="roleDropdown" item xs={12} sm={12} md={12} lg={12}>
            <Select
              data-test-id="state-select-dropdown"
              id="demo-simple-select"
              value={this.state.vendorProfileData["profile[state]"]}
              label="Age" displayEmpty
              onChange={(e: any) => this.handleChangeProfileDetails(e, "profile[state]")}
              onFocus={() => {
                this.handleInputFocus("stateDropdown");
              }}
              onBlur={() => {
                this.handleInputBlur("stateDropdown");
              }}
            >
              <MenuItem value="">Select your state</MenuItem>
              {this.state.stateData.map((state: any) => {
                return <MenuItem key={"state" + state.iso2} value={state.iso2}>{state.name}</MenuItem>
                {console.log("iso",state)}
              })}
            </Select>
            {this.vendorProfileRenderErrorMessage(this.state.vendorProfileDataError["profile[state]"].require, "State is required")}
          </Grid>
      

          </Box>

          <Box style={{ width: '100%' }}>
            <CustomInputlabelEmail id="StateName" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px" }}>City</CustomInputlabelEmail>
            <Grid className="roleDropdown" item xs={12} sm={12} md={12} lg={12}>
            <Select
              data-test-id="city-select-dropdown"
              id="demo-simple-select" displayEmpty
              value={this.state.vendorProfileData["profile[city]"]}
              label="Age"
              onChange={(e: any) => this.handleChangeProfileDetails(e, "profile[city]")}
              onFocus={() => {
                this.handleInputFocus("cityDropdown");
              }}
              onBlur={() => {
                this.handleInputBlur("cityDropdown");
              }}
            >
              <MenuItem value="">Select your city</MenuItem>
              {this.state.cityData.length ? this.state.cityData.map((city: any) => {
                return <MenuItem key={"city" + city.name} value={city.name}>{city.name}</MenuItem>
              }) : null}
            </Select>
              {this.vendorProfileRenderErrorMessage(this.state.vendorProfileDataError["profile[city]"].require, "City is Required")}

            </Grid>

          </Box>


        </Box>
      </Box>
    </>

  }
  renderVendorProfileStepTwoView() {
    return <>
      <Grid item style={{ marginTop: "24px", paddingInline: "12px", display: "flex" }} xs={12} sm={12} md={12} lg={12}>
        <Grid container style={{ marginRight: "24px", width: "calc(100% - 156px)", height: "125px", borderRadius: "12px", backgroundColor: "#f8fafe", border: "1px solid #e3eaf4", boxSizing: "border-box", color: "#17181d", fontFamily: "Urbanist", fontSize: "12px", fontWeight: 500, lineHeight: "16px" }}>
          <Typography style={webStyle.vendorProfileAllReviewsTitle as React.CSSProperties}>Upload Studio’s Certificates & Docs</Typography>
          <Grid key={this.state.vendorProfileData["studio[certificates][]"].length} style={{ padding: "2px 7px 7px 7px", display: "flex", overflowX: "auto", width: "calc(100% - 23px)" }}>
            {this.state.vendorProfileData["studio[certificates][]"].length ?
              this.state.vendorProfileData["studio[certificates][]"].map((document: any, index: number) => {
                return <Grid item key={`${index + document.id}_documentlist`} style={{ width: "112px" }}>
                  <Box style={{ background: "#ffffff", margin: "4%", padding: "4%" }}>
                    <Box style={{ width: "94px" }} className="vendor_profile_settings_cert_box">
                      <Box className="cert_inner_tool">
                        <Box style={{ backgroundColor: "rgba(245,196,44,0.09)", borderRadius: "8px", padding: "8.5px 11.6px" }}>
                          <img style={{ width: "14.61px", height: "21px" } as React.CSSProperties} src={Raiseinvoicecertificate} alt="" />
                        </Box>
                        <IconButton data-test-id={`pdf_download_${document.id}`} onClick={() => this.vendorProfileHandleCancelPDF(document)} className="vendor_profile_download_icon" size="medium">
                          <CancelIcon fontSize="small" htmlColor="#6d89af" style={{ width: "14px", height: "14px" }} />
                        </IconButton>
                      </Box>
                      <Box className="project_name">
                        <Typography noWrap className="file_name">{document.filename || document.name}</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              }) : null
            }
          </Grid>
        </Grid>
        <Grid container style={{ width: "132px", height: "125px", borderRadius: "12px", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "rgba(245,196,44,0.04)", border: "1px dashed #f5c42c", }}>
          <AddCircleIcon onClick={this.handleClickAddDocuments} htmlColor="#f5c42c" style={{ width: "20px", height: "20px", cursor: "pointer" }} />
          <input data-test-id="addDocumentsInput" type="file" ref={this.fileUpload} accept=".jpg, .jpeg, .png, .pdf" onChange={this.handleSelectDocuments} className="filetype" multiple 
          style={{ display: "none" }}
           />



        </Grid>
      </Grid>

      <Grid item style={{ marginTop: "24px", paddingInline: "12px", display: "flex" }} xs={12} sm={12} md={12} lg={12}>
        <Grid container style={{ marginRight: "24px", width: "calc(100% - 156px)", height: "125px", borderRadius: "12px", backgroundColor: "#f8fafe", border: "1px solid #e3eaf4", boxSizing: "border-box", color: "#17181d", fontFamily: "Urbanist", fontSize: "12px", fontWeight: 500, lineHeight: "16px" }}>
          <Typography style={webStyle.vendorProfileAllReviewsTitle as React.CSSProperties}>Upload Studio Pictures</Typography>
          <Grid key={this.state.vendorProfileData["studio[photos][]"].length} style={{ padding: "2px 7px 7px 7px", display: "flex", overflowX: "auto", width: "calc(100% - 23px)", gap: '15px' }}>
            {this.state.vendorProfileData["studio[photos][]"].length ?
              this.state.vendorProfileData["studio[photos][]"].map((image: any, index: number) => {
                return <Grid item key={`${index + image.id}_documentlist`} style={{ width: "112px" }}>
                  <Box style={{ background: "#ffffff", margin: "4%", padding: "4%" }}>
                    <Box style={{ width: "94px" }} className="vendor_profile_settings_cert_box">
                      <Box className="cert_inner_tool">
                        <Box style={{ position: "relative", backgroundColor: "rgba(245,196,44,0.09)", borderRadius: "8px", padding: "8.5px 11.6px" }}>
            {this.state.editstudio==="editstudio"? (
            <Grid style={webStyle.filterDiv}>
                    <img
                      style={{ width: "112px", height: "60px" } as React.CSSProperties}
                      src={
                        image
                          ? typeof image === "string"
                            ? image
                            : image instanceof Blob
                            ? URL.createObjectURL(image)
                            : image.path_url
                          : ""
                      }
                      alt="" 
                      />
            </Grid>
            ):(
              <img
              style={{ width: "112px", height: "60px" } as React.CSSProperties}
              src={
                image
                  ? typeof image === "string"
                    ? image
                    : image instanceof Blob
                    ? URL.createObjectURL(image)
                    : image.path_url
                  : ""
              }
              alt=""
            />)}
                          <IconButton data-test-id={`pdf_download_${image.id}`} style={{ position: "absolute", top: 14, right: 15 }} onClick={() => this.vendorProfileHandleCancelImage(image)} className="vendor_profile_download_icon" size="medium">
                            <CancelIcon fontSize="small" htmlColor="#6d89af" style={{ width: "14px", height: "14px" }} />
                          </IconButton>                          
                          </Box>

                      </Box>

                    </Box>
                  </Box>
                </Grid>
              }) : null
            }
          </Grid>
        </Grid>
        <Grid container style={{ width: "132px", height: "125px", borderRadius: "12px", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "rgba(245,196,44,0.04)", border: "1px dashed #f5c42c", }}>
          <AddCircleIcon onClick={this.handleClickAddMore} htmlColor="#f5c42c" style={{ width: "20px", height: "20px", cursor: "pointer" }} />
          <input data-test-id="addDocumentsInput" type="file" ref={this.imageUpload} accept=".jpg, .jpeg, .png, .pdf" onChange={this.handleAddMorePhotos} className="filetype" multiple style={{ display: "none" }} />
        </Grid>
      </Grid>
    </>
  }


  render() {
    return (
      <PatentGrid style={webStyle.ParentMain}>
        <Grid style={webStyle.ParentDiv}>
          <Grid style={webStyle.HeaderDiv as React.CSSProperties}>
            {this.state.editstudio==="editstudio"?
            (
              <Grid style={webStyle.filterDiv}>
              Edit Studio
            </Grid>

            ):(
<Grid style={webStyle.filterDiv}>
              Add New Studio
            </Grid>
            )
            
          }
            
            <Grid style={webStyle.filterDiv}>
              <CloseIcon onClick={this.props.handleClose1} style={{ color: "#6d89af" }} />
            </Grid>

          </Grid>
          <Box style={{ width: "100%", paddingBottom: "22px" }}>
            <Box style={{ width: '100%', display: "flex", justifyContent: "center", paddingBottom: '10px' }}>
              <Stepper style={{ width: '346.5px', paddingTop: "24px", marginLeft: '-12%', marginRight: '-9%' }} alternativeLabel activeStep={this.state.vendorProfileActiveStep} connector={<ColorlibConnector />}>
                {
                  ["Add Details", "Upload Documents"].map(
                    (label, index) => (<Step key={label}>
                      <StepLabel
                        className="stepLabelClass"
                        data-test-id={`stepLabelId_${index}`}
                        StepIconComponent={this.VendorProfileColorlibStepIcon}>{label}
                      </StepLabel>
                    </Step>))
                }
              </Stepper>
            </Box>
            {!this.state.vendorProfileActiveStep && this.renderVendorProfileStepOneView()}
            {this.state.vendorProfileActiveStep === 1 && this.renderVendorProfileStepTwoView()}

          </Box>
          <Grid item xs={12} sm={12} md={12} lg={12} style={{ justifyContent: "space-between", display: "flex", flex: "inherit", paddingBottom: "24px", marginTop: 'auto', marginInline: "24px" }}>
            <Grid item style={{ width: 163, visibility: this.state.vendorProfileActiveStep ? "visible" : "hidden", height: 48, backgroundColor: "#FFFFFF" } as React.CSSProperties} className="button_box">
              <CustomPHBackButtonBox data-test-id="sign_up_button" style={{ height: 48 } as React.CSSProperties} onClick={this.handleVendorProfileStepBack}>
                Back
              </CustomPHBackButtonBox>
            </Grid>
            {this.state.vendorProfileActiveStep === 1 ?
              <Grid item style={{ width: 202, height: 48 } as React.CSSProperties} className="button_box">
                {
                  this.state.editstudio==="editstudio"
                  ?(
                    <CustomPHSignUPButtonBox data-test-id="sign_up_button" style={{ height: 48, backgroundColor: "#17181d" } as React.CSSProperties}
                    onClick={this.studioupdateapi}
                  >
                    Save Studio
                   </CustomPHSignUPButtonBox>
                  ):(
                    <CustomPHSignUPButtonBox data-test-id="sign_up_button" style={{ height: 48, backgroundColor: "#17181d" } as React.CSSProperties}
                    onClick={this.studiocreationapi}
                  >
                   
                    Save Studio
  
                  </CustomPHSignUPButtonBox>
                  )
                }
               
              </Grid>
              :
              <Grid item style={{ width: 163, height: 48 } as React.CSSProperties} className="button_box">
                <CustomPHSignUPButtonBox data-test-id="next_button" style={{ height: 48, backgroundColor: "#17181d" } as React.CSSProperties} onClick={this.handleVendorProfileMoveOnNextPage}>
                  Next
                </CustomPHSignUPButtonBox>
              </Grid>}
          </Grid>


        </Grid>
        <CustomNotification navigation={(route: string) => this.props.navigation.navigate(route)} notification={this.state.vendorProfileNotification} handleCloseNotification={this.handleVendorProfileCloseNotification} />
      </PatentGrid>

    );
  }
}

const webStyle = {
  ParentMain: {



  },
  ParentDiv: {

    //border: '10px 1px solid #d8d8d8',

    //width: '720',
    //minHeight: '702px',
    // "@media screen and (max-width: 500px)": {

    //   width: '400px',
    //   marginRight: "0px",

    // }


  },
  uploadicon: {
    height: '18px',
    width: "18px",
  },
  IMAGEBOX: {
    width: '117px',
    height: "47px",
    background: '#ffffff',
    borderRadius: "12px",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  uploadimage: {
    height: "30px",
    width: '27px'
  },


  invoiceheadingandsubheading: {},
  invoiceuploadicon: {
    width: '38px',
    height: '38px',
    borderRadius: '12px',
    background: 'rgba(245,196,44,0.12)',
    display: 'flex', justifyContent: 'center',
    alignItems: 'center'
  },
  invoiceheading: {
   fontWeight:600,
   fontFamily:'urbanist'
  },
  invoicesubheading: {
    color: '#6d89af',
    fontFamily: 'Urbanist',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '18px',
    letterSpacing: '0.18461539px',
    textAlign: 'center',
  },

  uploadinvoicereceiptdiv: {
    //marginTop: '30px',
    //width: '272px',
    height: '70px',
    borderRadius: '14px',
    border: '0.75px dashed #f5c42c',
    background: 'rgba(245,196,44,0.04)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    
  },
  filterDiv: {
    fontSize: "20px",
    fontWeight: 700,
    fontFamily: "urbanist",
    color: '#17181d',
    paddingBottom: "10px"
  },
  HeaderDiv: {
    fontSize: "20px",
    textAlign: 'center',
    fontWeight: 700,
    paddingTop: "20px",
    //paddingBottom: "10px",
    display: 'flex',
    justifyContent: 'end',
    gap: '35%',
    paddingRight: '20px'


  },
  vendorProfileAllReviewsTitle: {
    color: "#17181d",
    fontFamily: "Urbanist",
    fontSize: "12px",
    fontWeight: 600,
    padding: "12px 12px 0px 12px",
    width: "100%",
    lineHeight: "15px",
  },
};
