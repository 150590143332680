import React from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  TypographyProps,
  Link, Avatar, Button, Divider, LinearProgress
} from "@material-ui/core";
import SideBar from "../../../components/src/SideBar";
import Header from "../../../components/src/Header.web";
import Footer from "../../../components/src/Footer";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
//@ts-ignore
// @ts-ignore
// @ts-ignore
import Loader from "../../../components/src/Loader.web";
import StudiosBookingDetailController, {
  Props, configJSON
} from "./StudiosBookingDetailController";
import BusinessIcon from '@material-ui/icons/Business';
import SettingsPhoneIcon from '@material-ui/icons/SettingsPhone';
import PlaceOutlinedIcon from '@material-ui/icons/PlaceOutlined';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import StarOutlinedIcon from '@material-ui/icons/StarOutlined';
import { StyledBadge } from "../../fullteamvisibility/src/CardComponent.web";
import Rating from "@material-ui/lab/Rating";
import BookStudioModel from "./BookStudioModel.web";
import BookStudioSuccessModel from "./BookStudioSuccessModel.web";
import StudioDetailRatingModel from "./StudioDetailRatingModel.web";
import CustomNotification from "../../../components/src/CustomNotification";
const images = require("./assets");

const themeStyle = createTheme({
  palette: {
    primary: {
      main: "#F5C42C",
      contrastText: "#fff"
    }
  },
  overrides: {
    MuiSelect: {
      select: {
        "&:focus": {
          border: "1px solid #F5C42C",
        }
      }
    }
  }
});

export default class StudiosBookingDetail extends StudiosBookingDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderStudiosBookingDetailMainContent = (sideBarStatus: boolean = this.state.isStudioBookingDetailSideBarOpen) => {
    return <>
      <Loader loading={this.state.isLoading}></Loader>
      <Grid style={webStyle.wholePageContentSetup} >
        <Header handleSideBar={this.handleStudioDetailPageSideBar}
          dropDownStatus={this.state.isDropdownOpen}
          navigate={this.props.navigation.navigate}
          sideBarStatus={sideBarStatus}
          handleDropdown={this.handleDropdown}
        />
        <Grid style={webStyle.dynamicMainContent as React.CSSProperties}>
          <Box sx={webStyle.blockInfoTitle}>
            <Box>
              <Typography variant="h3" style={webStyle.activeBlockMainTitle as TypographyProps}>Studio Details</Typography>
              <Typography variant="h6" style={webStyle.blockE2EPath as TypographyProps}>
                <span style={webStyle.highlightedTitleext}>
                  <Link data-test-id="homeLink" color="inherit" onClick={() => this.handleNavigation("JobProfile")}>
                    Home
                  </Link>
                </span>
                <span style={webStyle.highlightedTitleext}>
                  <Link data-test-id="studiosBookingList" color="inherit" onClick={() => this.handleNavigation("StudiosBookingList")}>
                    {" "}/ Studios & Booking
                  </Link>
                </span>
                {" "}/{" "}<span title={this.state.studioDetailsData.name}>{this.state.studioDetailsData.name}</span>
              </Typography>
            </Box>
          </Box>
          <Grid style={{ marginTop: "24px", borderRadius: "12px", border: "1px solid #e3eaf4", background: "#ffffff" }} item sm={12} xl={12} xs={12} md={12} lg={12}>
            <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
              <Box style={{ display: "flex", margin: "16px 0 16px 16px", flexWrap: "wrap" }}>
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                  }}
                  style={{ width: 75, height: 75 }}
                  badgeContent={
                    <img style={{ width: 24, height: 24 }} src={images.checkedIcon} />
                  }
                >
                  <Avatar
                    style={{
                      border: "3px solid #f5c42c",
                      width: 69,
                      height: 69,
                      marginRight: 1
                    }}
                    src={this.state.studioDetailsData.image || "https://www.littlethings.info/wp-content/uploads/2014/04/dummy-image-green-e1398449160839.jpg"}
                  >

                  </Avatar>
                </StyledBadge>
                <Box style={webStyle.studioDetailsLeftBox as React.CSSProperties}>
                  <Typography
                    title={this.state.studioDetailsData.name}
                    style={{
                      height: "29px",
                      color: "#17181d",
                      fontFamily: "Urbanist",
                      fontSize: "13px",
                      fontWeight: 600,
                      textAlign: "center",
                      letterSpacing: "0.225px",
                      maxWidth: "300px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {this.state.studioDetailsData.name}
                  </Typography>
                  <div style={webStyle.phNameAddressBox} >
                    <Typography title={this.state.studioDetailsData.production_house} style={webStyle.phName}>
                      <BusinessIcon style={{
                        fontSize: "small",
                        width: "22px",
                        height: "22px",
                        marginRight: 3,
                      }} />
                      {this.state.studioDetailsData.production_house}
                    </Typography>
                    <Typography style={webStyle.addressText}>
                      <PlaceOutlinedIcon style={{
                        fontSize: "small",
                        width: "22px",
                        height: "22px",
                        marginRight: 2,
                      }} />
                      {this.state.studioDetailsData.city}, {this.state.studioDetailsData.state}
                    </Typography>
                  </div>
                  <Box style={{ ...webStyle.studioStatusBtn, background: this.getStatusBG(this.state.studioDetailsData.status, "bg"), width: this.getStatusBG(this.state.studioDetailsData.status, "wid") }}>
                    <span style={webStyle.studioStatusText} >{this.state.studioDetailsData.status?.toUpperCase()}</span>
                  </Box>
                </Box>
                <Box style={webStyle.studioDetailsRightBox as React.CSSProperties}>
                  <Typography style={webStyle.rightSideBoxText}>
                    <SettingsPhoneIcon style={{
                      fontSize: "small",
                      width: "20.09px",
                      height: "20px",
                      marginRight: 10.4,
                      color: "#f5c42c",
                    }} />
                    +{this.state.studioDetailsData.country_code}-{this.state.studioDetailsData.phone_number}
                  </Typography>
                  <Typography style={{ ...webStyle.rightSideBoxText, margin: "9px 0px" }}>
                    <img style={{ marginRight: 10.4 }} src={`${images.forwardMailIcon}`} />
                    {this.state.studioDetailsData.email}
                  </Typography>
                  <Typography style={webStyle.rightSideBoxText}>
                    <img style={{ marginRight: 10.4 }} src={`${images.studioTimingIcon}`} />
                    {this.state.studioDetailsData.open_time} to {this.state.studioDetailsData.close_time}
                  </Typography>
                </Box>
              </Box>
              <Box className="studioBookingBookStudioResponsive">
                <Button onClick={this.handleBookStudioModelOpen} style={webStyle.bookStudioBtn}><span style={webStyle.bookStudioBtnText as React.CSSProperties}>Book Studio</span></Button>
              </Box>
            </Box>
          </Grid>
          <Grid container style={{ width: "100%", marginTop: "24px", alignItems: "flex-start" }}>
            <Grid md={8} sm={12} item style={{ maxHeight: "630px", minHeight: "406px", borderRadius: "12px", border: "1px solid #e3eaf4" }}>
              <Box style={{ display: "flex" }}>
                <Box style={webStyle.lefttabsMainBox}>
                  <Box data-test-id="studioDetailOverviewTab" onClick={() => this.handleTabValueChange(0)} style={{ borderBottom: "1px solid #e3eaf4", cursor: "pointer", width: "138px" }}>
                    <Typography style={{ ...webStyle.leftSideTabText, background: this.state.studioDetailTabValue === 0 ? "#ffffff" : "#f8fafe", color: this.state.studioDetailTabValue === 0 ? "#f5c42c" : "#17181d" }}>Overview</Typography>
                  </Box>
                  <Box data-test-id="studioDetailGalleryTab" onClick={() => this.handleTabValueChange(1)} style={{ borderBottom: "1px solid #e3eaf4", cursor: "pointer", width: "138px" }}>
                    <Typography style={{ ...webStyle.leftSideTabText, background: this.state.studioDetailTabValue === 1 ? "#ffffff" : "#f8fafe", color: this.state.studioDetailTabValue === 1 ? "#f5c42c" : "#17181d" }}>Gallery</Typography>
                  </Box>
                </Box>
                {this.state.studioDetailTabValue === 0 ? <Box style={{ width: "calc(100% - 186px)", margin: "13px 24px 16px 24px" }}>
                  <Box>
                    <Typography style={webStyle.allReviewsTitle as React.CSSProperties}>Price Details --------------------------------------------------------------------------------------------------------------------</Typography>
                    <Grid container style={{ marginTop: "8px", width: "100%", height: "87px", borderRadius: "12px", border: "1px solid #e3eaf4", padding: 6, display: "flex", flexDirection: "column" }}>
                      <Grid item sm={6} md={6} lg={6} style={{ height: "59px", margin: "8px", borderRadius: "10px", background: "#e1e6ec", padding: "10px", boxSizing: "border-box" }}>
                        <Typography style={{ color: "#17181d", fontFamily: "Urbanist", fontSize: "12px", fontWeight: 600, lineHeight: "16px", letterSpacing: "0.2px" }}>Per day</Typography>
                        <div style={{ marginTop: "4px", color: "#42a200", fontFamily: "Helvetica-Bold", fontSize: "18px", fontWeight: 400, lineHeight: "19px", letterSpacing: "0.16363636px" }}>₹{this.state.studioDetailsData.price_per_day}</div>
                      </Grid>
                      <Grid item sm={6} md={6} lg={6} style={{ height: "59px", margin: "8px", borderRadius: "10px", background: "#e1e6ec", padding: "10px", boxSizing: "border-box" }}>
                        <Typography style={{ color: "#17181d", fontFamily: "Urbanist", fontSize: "12px", fontWeight: 600, lineHeight: "16px", letterSpacing: "0.2px" }}>Per hour</Typography>
                        <div style={{ marginTop: "4px", color: "#42a200", fontFamily: "Helvetica-Bold", fontSize: "18px", fontWeight: 400, lineHeight: "19px", letterSpacing: "0.16363636px" }}>₹{this.state.studioDetailsData.price_per_hour}</div>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box style={{ marginTop: "16px" }}>
                    <Typography style={webStyle.allReviewsTitle as React.CSSProperties}>Studio For --------------------------------------------------------------------------------------------------------------------</Typography>
                    <Grid container style={{ marginTop: "8px", width: "100%", minHeight: "86px", borderRadius: "12px", border: "1px solid #e3eaf4", padding: 16, boxSizing: "border-box", display: "flex", flexDirection: "column" }}>
                      <Grid container spacing={3}>
                        {this.state.studioDetailsData.studio_type?.map((studio: any) => (
                          <Grid
                            key={`studiofor_${studio}`}
                            style={{ display: 'flex', gap: '4px', alignItems: "center" }}
                            item
                            md={4}
                          >
                            <Box>
                              <img src={images.RightIcon} alt="icon" />
                            </Box>
                            <Box style={{ fontWeight: 'bold', fontSize: "12px" }}>{studio}</Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Box>
                  <Box style={{ marginTop: "16px" }}>
                    <Typography style={webStyle.allReviewsTitle as React.CSSProperties}>About Studio --------------------------------------------------------------------------------------------------------------------</Typography>
                    <Grid container style={{ marginTop: "8px", width: "100%", minHeight: "88px", borderRadius: "12px", border: "1px solid #e3eaf4", padding: 12, boxSizing: "border-box", color: "#17181d", fontFamily: "Urbanist", fontSize: "12px", fontWeight: 500, lineHeight: "16px", overflowY: "scroll", height: "100px", wordBreak: "break-word" }}>
                      {this.state.studioDetailsData.description}
                    </Grid>
                  </Box>
                </Box> : null}
                {this.state.studioDetailTabValue === 1 ? <Box style={{ width: "100%", margin: "15px" }}>
                  <Box style={{ display: "flex", flexDirection: "row", gap: "13px", flexWrap: "wrap" }}>
                    {this.state.studioDetailsData.photos ? this.state.studioDetailsData.photos.map((item: any) => {
                      return <img key={item.id + "gallery"}
                        className="studioGalleryImages"
                        src={item.path_url || "https://www.littlethings.info/wp-content/uploads/2014/04/dummy-image-green-e1398449160839.jpg"}
                      />
                    })
                      : <Typography style={{ width: '100%', textAlign: 'center', marginTop: '30px', marginBottom: '30px', color: '#6d89af', fontFamily: 'Urbanist', fontSize: 14, fontWeight: 600 }}>No photos available</Typography>
                    }
                  </Box>
                </Box> : null}
              </Box>
            </Grid>
            <Grid md={4} sm={12} item className="studioBookingResponsive" style={{ paddingLeft: "24px" }}>
              <Grid style={{ width: "100%", maxHeight: "630px", minHeight: "400px", borderRadius: "12px", border: "1px solid #e3eaf4", background: "#ffffff" }}>
                <Box style={webStyle.ratingStartMainBox}>
                  <Box style={webStyle.ratingStartBox} >
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <Box className="star_icon rating_size">
                        <Typography className="total_rating_typo">{this.state.studioDetailReviewData.average_rating || 0}</Typography>
                        <Typography className="divide_num">{configJSON.divide}</Typography>
                      </Box>
                      <Box style={{ marginLeft: "12px", marginRight: "10px" }} className="rating_text">
                        <Divider orientation="vertical" flexItem style={{ height: 23 } as React.CSSProperties} />
                      </Box>
                      <Rating
                        name="customized-empty"
                        value={this.state.studioDetailReviewData.average_rating || 0}
                        size="small"
                        precision={0.5}
                        readOnly
                        emptyIcon={<StarBorderIcon htmlColor="#ffb400" style={{ width: "18px", height: "18px" } as React.CSSProperties} />}
                      />
                    </Box>
                    <Box>
                      <Typography style={webStyle.rateNowText} onClick={this.handleRatingModelOpen}>
                        Rate Now
                        <ArrowForwardIcon style={{
                          fontSize: "small",
                          width: "14px",
                          height: "14px",
                          marginLeft: 1,
                        }}
                        />
                      </Typography>
                    </Box>
                  </Box>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="user_detail_grid_container_rating">
                    <Typography className="rating_helper">{this.state.studioDetailReviewData.review_rating_count}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="user_detail_grid_container_rating background_color">
                    <Divider style={{ marginTop: "12px", marginBottom: "15px" }} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ margin: "0px 12px 16px", display: "flex", justifyContent: "space-between" }}>
                    {
                      Object.entries(this.state.studioDetailReviewData.rating_star || { 5: 0, 4: 0, 3: 0, 2: 0, 1: 0 }).reverse().map((item: any, index: number) => {
                        return <Box style={{ width: "calc(20% - 8px)", display: "flex", flexDirection: "column" }} key={`${item[0]}_ratingvalue`}>
                          <LinearProgress variant="determinate" value={item[1] | 0} className="progress_bar_rating" style={{ backgroundColor: '#e3eaf4', borderRadius: '30px', marginBottom: "5px" }} />
                          <Typography style={{ height: "12px", color: "#17181d", fontFamily: "Urbanist", fontSize: "10px", fontWeight: 600, letterSpacing: "0.2px" }}>{item[0]}{" "}Star</Typography>
                        </Box>
                      })
                    }
                  </Grid>
                </Box>
                <Box style={{ display: "flex", alignItems: "center", margin: "0px 12px" }}>
                  <Typography style={webStyle.allReviewsTitle as React.CSSProperties}>All Reviews --------------------------------------------------</Typography>
                </Box>
                <Grid style={{ margin: "0px 12px", maxHeight: "478px", overflowY: "auto", msOverflowStyle: "none", scrollbarWidth: "none" }} item xs={12} sm={12} md={12} lg={12} xl={12}>
                  {
                    this.state.studioDetailReviewData.reviews?.data?.length ? (this.state.studioDetailReviewData.reviews.data.map((item: any, index: number) => {
                      return <Box key={`${index}_review_box_allProjectList`} style={{ padding: '12px' }} className="review_box">
                        <Grid container spacing={3}>
                          <Box sx={webStyle.projectcardParentBox} className="review_card_box">
                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                              <Avatar
                                style={{ borderRadius: "12px", width: 36, height: 36 }}
                                src={item.attributes.account.image || "https://www.littlethings.info/wp-content/uploads/2014/04/dummy-image-green-e1398449160839.jpg"}
                              >
                              </Avatar>
                            </Grid>
                            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                              <Typography style={{ lineHeight: "19px", color: "#17181d", fontFamily: "Urbanist", fontSize: "16px", fontWeight: 600, letterSpacing: "0.2px" }}>{item.attributes.account.full_name}</Typography>
                              <Typography style={{ marginTop: "1px", lineHeight: "12px", color: "#6d89af", fontFamily: "Urbanist", fontSize: "10px", fontWeight: 500, letterSpacing: "0.2px" }}>{item.attributes.created_at}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2} className="project_date">
                              <Box style={webStyle.stars}>
                                <StarOutlinedIcon className="start_color" />
                                <Typography style={webStyle.star_color as React.CSSProperties}>&nbsp;{item.attributes.given_star}</Typography>
                              </Box>
                            </Grid>
                          </Box>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: "0px 12px 12px", marginTop: '-2%' }}>
                            <Typography style={{ color: "#17181d", fontFamily: "Urbanist", fontSize: "12px", fontWeight: 500, lineHeight: "16px", letterSpacing: "0.2px" }}>{item.attributes.comment}</Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    })) : null
                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </>
  }
  // Customizable Area End

  render() {
    return (
      <ThemeProvider theme={themeStyle}>
        <Container style={webStyle.parentContainer}>
          {
            this.state.isStudioBookingDetailSideBarOpen ? (
              <Grid spacing={0} container>
                <Grid xs={3} item style={webStyle.sidebarParentGrid} >
                  <SideBar
                    handleSideBar={this.handleStudioDetailPageSideBar}
                    userData={{ name: "StudiosBookingList" }}
                    activeMenuItem={"Studios & Booking"}
                    navigate={this.props.navigation.navigate}
                  />
                </Grid>
                <Grid style={{ display: "flex", flexDirection: "column", flexBasis: "77.75%", maxWidth: "77.75%" }} item xs={9} >
                  {this.renderStudiosBookingDetailMainContent()}
                </Grid>
              </Grid>
            ) : (
              <Grid item style={{ display: "flex", flexDirection: "column" }} xs={12}>
                {this.renderStudiosBookingDetailMainContent()}
              </Grid>
            )
          }
          {this.state.isModelOpen && this.state.modelType === "" ? <BookStudioModel navigation={this.props.navigation} id={this.state.studioBookingId} open={this.state.isModelOpen} handleClose={this.handleModelClose} sendBookingRequest={this.sendBookingRequest} pricePerDay={this.state.studioDetailsData.price_per_day} pricePerHour={this.state.studioDetailsData.price_per_hour} /> : null}
          {this.state.isModelOpen && this.state.modelType === "successModel" ? <BookStudioSuccessModel navigation={this.props.navigation} id={this.state.studioBookingId} open={this.state.isModelOpen} handleClose={this.handleModelClose} studioName={this.state.studioDetailsData.name} /> : null}
          {this.state.isModelOpen && this.state.modelType === "ratingModel" ? <StudioDetailRatingModel navigation={this.props.navigation} id={this.state.studioBookingId} open={this.state.isModelOpen} handleClose={this.handleModelClose} studioName={this.state.studioDetailsData.name} studioImage={this.state.studioDetailsData.image} handleGiveRatingAPICall={this.handleGiveRatingAPICall} /> : null}
        </Container>
        <CustomNotification navigation={(route: string) => this.props.navigation.navigate(route)} notification={this.state.notification} handleCloseNotification={this.handleCloseNotification} />
      </ThemeProvider>
    );
  }
}

const webStyle = {
  lefttabsMainBox: {
    width: "138px",
    height: "100%",
    background: "#f8fafe",
  },
  leftSideTabText: {
    lineHeight: "17px",
    color: "#17181d",
    fontFamily: "Urbanist",
    fontSize: "14px",
    fontWeight: 600,
    padding: "27px 16px 21px 16px",
  },
  allReviewsTitle: {
    color: "#6d89af",
    fontFamily: "Urbanist",
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "19px",
    whiteSpace: "nowrap",
    textOverflow: "clip",
    overflow: "hidden",
  },
  stars: {
    padding: "0px 8px",
    background: "linear-gradient(225deg, #58A2FF 0%, #1777E2 100%)",
    borderRadius: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: 'space-evenly'
  },
  star_color: {
    lineHeight: "17px",
    color: "#ffffff",
    fontFamily: "Urbanist",
    fontSize: "14px",
    fontWeight: 600,
    textAlign: "center",
  },
  projectcardParentBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    padding: '2%'
  },
  studioStatusBtn: {
    width: "73px",
    height: "23px",
    borderRadius: "12px",
    background: "#3389ff",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  studioStatusText: {
    height: "12px",
    color: "#ffffff",
    fontFamily: "Urbanist",
    fontSize: "10px",
    fontWeight: 700,
    letterSpacing: "0.18181819px",
  },
  phNameAddressBox: {
    display: "flex",
  },
  rightSideBoxText: {
    lineHeight: "14px",
    color: "#17181d",
    fontFamily: "Urbanist",
    fontSize: "14px",
    fontWeight: 600,
    letterSpacing: "0.23333333px",
    alignItems: "center",
    display: "flex",
  },
  studioDetailsRightBox: {
    paddingLeft: "24.5px",
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
  },
  studioDetailsLeftBox: {
    borderRight: "1px solid #e3eaf4",
    height: "79px",
    paddingRight: "24px",
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    marginLeft: "10px",
  },
  addressText: {
    lineHeight: "17px",
    color: "#6d89af",
    fontFamily: "Urbanist",
    fontSize: "14px",
    fontWeight: 500,
    letterSpacing: "0.23333333px",
    alignItems: "center",
    display: "flex",
    margin: "6px 0px",
    paddingLeft: "11.5px",
  },
  phName: {
    lineHeight: "17px",
    color: "#17181d",
    fontFamily: "Urbanist",
    fontSize: "14px",
    fontWeight: 500,
    textDecoration: "underline",
    letterSpacing: "0.23333333px",
    alignItems: "center",
    display: "inline-flex",
    margin: "6px 0px",
    paddingRight: "11.5px",
    borderRight: "1px solid #e3eaf4",
    height: "18px",
    maxWidth: "300px",
    whiteSpace: "nowrap" as "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"

  },
  bookStudioBtn: {
    width: "159px",
    height: "48px",
    borderRadius: "14px",
    background: "#17181d",
    marginRight: "24px",
  },
  bookStudioBtnText: {
    color: "#ffffff",
    fontFamily: "Urbanist",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: "0.45714286px",
    textTransform: "none",
  },
  ratingStartMainBox: {
    width: "100%",
  },
  ratingStartBox: {
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  rateNowText: {
    height: "14px",
    color: "#f5c42c",
    fontFamily: "Urbanist",
    fontSize: "12px",
    fontWeight: 600,
    textDecoration: "underline",
    letterSpacing: "0.18461539px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  parentContainer: {
    maxWidth: "none",
    padding: "0px",
  },
  sidebarParentGrid: {
    maxWidth: "22.25%",
    minHeight: "100vh",
  },
  wholePageContentSetup: {
    borderLeft: "1px solid #E3EAF4",
    // minHeight: "90vh",
    flex: 1,
  },
  dynamicMainContent: {
    minHeight: "100vh",
    width: "100%",
    boxSizing: "border-box",
    padding: "24px",
    backgroundColor: "#F8FAFE",
  },
  blockInfoTitle: {
    justifyContent: "space-between",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  highlightedTitleext: {
    fontWeight: 600,
    fontFamily: "Urbanist",
    fontSize: 14,
    color: "#F5C42C",
    textDecoration: "underline",
    cursor: "pointer",
  },
  activeBlockMainTitle: {
    lineHeight: "34px",
    fontSize: "28px",
    color: "#17181D",
    fontWeight: "700",
    letterSpacing: "1px",
  },
  blockE2EPath: {
    fontSize: "14px",
    letterSpacing: "1px",
    fontWeight: 500,
    color: "#6D89AF",
    lineHeight: "17px",
    paddingTop: "8px",
    display: "inline-block",
    maxWidth: "500px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  }
};
